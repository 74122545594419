import { createActions } from 'redux-actions';

import { ACTION_TYPES, ENDPOINTS } from './constants';
import { createRequestAction } from '../../common/helpers';

const actionCreators = createActions({
  [ACTION_TYPES.CHANGE_CATEGORY]: null,
  [ACTION_TYPES.CHANGE_LOCATION]: null,
  [ACTION_TYPES.CHANGE_SEARCH]: null,
  [ACTION_TYPES.CHANGE_CURRENCY]: null,
  [ACTION_TYPES.CHANGE_ORDER]: null,
  [ACTION_TYPES.CHANGE_GTE]: null,
  [ACTION_TYPES.CHANGE_LTE]: null,
  [ACTION_TYPES.CHANGE_OPTIONS]: null,
  [ACTION_TYPES.CHANGE_PARAMETERS]: null,
  [ACTION_TYPES.CLEAR_FILTERS]: null,
  ...createRequestAction(ACTION_TYPES.GET_CATEGORY_AND_CHILDREN),
  ...createRequestAction(ACTION_TYPES.GET_SEARCH_TIPS),
});

export const changeCategoryAsync = (category) => async (dispatch) => {
  dispatch(actionCreators.changeCategory(category));
};

export const changeLocationAsync = (location) => async (dispatch) => {
  dispatch(actionCreators.changeLocation(location === 2 ? 'all' : location));
};

export const changeSearchAsync = (text) => async (dispatch) => {
  dispatch(actionCreators.changeSearch(text));
};

export const changeCurrencyAsync = (text) => async (dispatch) => {
  dispatch(actionCreators.changeCurrency(text));
};

export const changeOrderAsync = (text) => async (dispatch) => {
  dispatch(actionCreators.changeOrder(text));
};

export const changeGteAsync = (text) => async (dispatch) => {
  dispatch(actionCreators.changeGte(text));
};

export const changeLteAsync = (text) => async (dispatch) => {
  dispatch(actionCreators.changeLte(text));
};

export const changeOptionsAsync = (options) => async (dispatch) => {
  dispatch(actionCreators.changeOptions(options));
};

export const changeParametersAsync = (params) => async (dispatch) => {
  dispatch(actionCreators.changeParameters(params));
};

export const clearFiltersAsync = () => async (dispatch) => {
  dispatch(actionCreators.clearFilters());
};

export function getCategoryAndChildren(id) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getCategoryAndChildrenRequest());
    try {
      const { data } = await api.get(`${ENDPOINTS.CATEGORY_AND_CHILDREN}${id}`);
      dispatch(actionCreators.getCategoryAndChildrenSuccess(data));
      return data;
    } catch (e) {
      dispatch(actionCreators.getCategoryAndChildrenFail(e));
      return e;
    }
  };
}

export function getSearchTips(params) {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getSearchTipsRequest());
    try {
      const { data } = await api.get(ENDPOINTS.SEARCH_TIPS, { params });
      dispatch(actionCreators.getSearchTipsSuccess(data));
      return data;
    } catch (e) {
      dispatch(actionCreators.getSearchTipsFail(e));
      return e;
    }
  };
}

export const actions = {
  ...actionCreators,
  changeCategoryAsync,
  changeLocationAsync,
  changeSearchAsync,
  changeCurrencyAsync,
  changeOrderAsync,
  changeGteAsync,
  changeLteAsync,
  changeOptionsAsync,
  changeParametersAsync,
  clearFiltersAsync,
  getCategoryAndChildren,
  getSearchTips,
};
