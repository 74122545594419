import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useStyles } from './styles';
import { NavLink, useLocation } from 'react-router-dom';
import GooglePlay from 'assets/images/google_play.svg';
import Footer from 'messages/Footer';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo.svg';
import { MEDIA } from 'business/config/constants/styleVariables';

const Index = (props) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: ${MEDIA.TABLET}px)`);
  const { intl } = props;
  const { pathname } = useLocation();
  const currentYear = new Date().getFullYear();

  if (pathname === '/chats' && isMobile) return <></>;

  return (
    <footer
      id="footer"
      className={classes.footer}
    >
      <div>
        <div className={classes.big}>
          <div>
            <Typography className={classes.name}>TEZ SAT</Typography>
            <Typography className={classes.bigTitle}>
              {intl.formatMessage({ ...Footer.aboutTezSat })}
            </Typography>
          </div>
          <div className={classes.navigations}>
            <Typography className={classes.name}>
              {intl.formatMessage({ ...Footer.more })}
            </Typography>
            <ul>
              <li>
                <a
                  href="https://api.whatsapp.com/send/?phone=996707797800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ ...Footer.support })}
                </a>
              </li>
              <li>
                <NavLink to="/sitemap/">
                  {intl.formatMessage({ ...Footer.sitemap })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">
                  {intl.formatMessage({ ...Footer.aboutUs })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/policy/">
                  {intl.formatMessage({ ...Footer.policy })}
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog/forbidden-products">
                  {intl.formatMessage({ ...Footer.forbidden })}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={classes.downloadApps}>
            <Typography className={classes.name}>
              {intl.formatMessage({ ...Footer.downloadApp })}
            </Typography>
            <a
              href="https://play.google.com/store/apps/details?id=com.tezsat.tezsat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={GooglePlay}
                alt="Google Play"
              />
            </a>
          </div>
        </div>
        <div className={classes.small}>
          <NavLink
            className={classes.logo}
            to="/"
          >
            <img
              src={logo}
              alt="Логотип"
            />
          </NavLink>
          <div className={classes.flexes}>
            <div className={classes.text}>
              <Typography>
                © {currentYear} {intl.formatMessage({ ...Footer.reserved })}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Index.propTypes = {
  intl: PropTypes.object,
};

export default Index;
