import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import NodeSelect from 'components/NodeSelect';
import Select from 'components/Select';

import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { productSelectors } from 'states/Products';

// MUI
import {
  Button,
  Collapse,
  MenuItem,
  Paper,
  TextField,
  useTheme,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import messages from 'messages/Filter';
import LoadingButton from 'components/LoadingButton';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { useStyles } from './styles';

import Mobile from './Mobile';
import Skeleton from './Skeleton';
import CategoryFilters from './CategoryFilters';
import CategoryFilter from './CategoryFilters/CategoryFilter';

function FilterComponent(props) {
  const {
    values: filters,
    categoryParams,
    categoryParamsLoading: isCategoryParamsLoading,
    locations,
    locationsObj,
    onChange,
    onSubmit,
    onReset,
    onBlur,
    isDisabled,
    intl,
  } = props;
  const isMobile = useMediaQuery('(max-width: 720px)');
  const theme = useTheme();
  const classes = useStyles();

  const [showAll, setShowAll] = useState(false);

  const isProductListLoading = useSelector(productSelectors.isListLoading);

  const regions = useMemo(() => {
    if (locations && locations[0]) {
      return [
        {
          id: locations[0]?.id,
          title_ru: 'Все',
          title_ky: 'Бары',
        },
        ...locations[0].children,
      ];
    }

    return [];
  }, [locations]);

  function streamlineFilters(filters) {
    if (!filters) return [];

    const filtered = [];
    let pairOfSelectFilters = [];

    filters.forEach((f) => {
      if (f.type === 'select') {
        pairOfSelectFilters.push(f);
        if (pairOfSelectFilters.length === 2) {
          filtered.push(...pairOfSelectFilters);
          pairOfSelectFilters = [];
        }
      } else {
        filtered.push(f);
      }
    });
    return filtered;
  }

  const filtered = useMemo(() => {
    if (categoryParams) {
      return streamlineFilters(categoryParams.slice(2));
    }
    return [];
  }, [categoryParams]);

  if (isMobile) {
    return (
      <Mobile
        categoryParams={categoryParams}
        intl={intl}
      />
    );
  }

  const handleCategoryParameterUpdate = (param) => {
    if (!param) return;

    const { parameters } = filters;
    const paramIdx = parameters.findIndex((p) => param?.id === p?.id);
    if (paramIdx !== -1) {
      if (param.from === '' && param.to === '') {
        const newParameters = [
          ...parameters.slice(0, paramIdx),
          ...parameters.slice(paramIdx + 1),
        ];
        onChange({ target: { name: 'parameters', value: newParameters } });
        return;
      }

      const newParameters = [
        ...parameters.slice(0, paramIdx),
        param,
        ...parameters.slice(paramIdx + 1),
      ];

      onChange({ target: { name: 'parameters', value: newParameters } });
      return;
    }
    onChange({ target: { name: 'parameters', value: [...parameters, param] } });
  };

  const handleCategoryOptionClick = (option) => {
    const { options } = filters;
    const optionIdx = options.findIndex((opt) => opt?.id === option?.id);

    onChange({
      target: {
        name: 'options',
        value:
          optionIdx !== -1
            ? [...options.slice(0, optionIdx), ...options.slice(optionIdx + 1)]
            : [...options, option],
      },
    });
  };

  const locationsFilter = (
    <NodeSelect
      nodes={regions}
      name="selectedLocation"
      selected={
        filters.selectedLocation === 'all'
          ? 'all'
          : locationsObj[filters.selectedLocation]
      }
      onSelect={onChange}
      className={classes.locationsNodeSelect}
      inputProps={{
        style: {
          borderRadius: '10px',
        },
      }}
    />
  );

  const orderFilter = (
    <Select
      name="order"
      value={filters.order}
      onChange={(val) =>
        onChange({
          target: {
            name: 'order',
            value: val,
          },
        })
      }
      style={{
        width: '100%',
      }}
    >
      <MenuItem value="-upvote_date">
        {intl.formatMessage({ ...messages.new })}
        {/* Сначала новые */}
      </MenuItem>
      <MenuItem value="price">
        {intl.formatMessage({ ...messages.cheap })}
        {/* Сначала дешевле */}
      </MenuItem>
      <MenuItem value="-price">
        {intl.formatMessage({ ...messages.expensive })}
        {/* Сначала дороже */}
      </MenuItem>
    </Select>
  );

  const currencyFilter = (
    <Paper
      elevation={0}
      className={classes.currency}
    >
      <ToggleButtonGroup
        exclusive
        className={classes.currencyItems}
        value={filters.currency}
        onChange={(_, value) =>
          onChange({
            target: { name: 'currency', value: value || filters.currency },
          })
        }
      >
        <ToggleButton
          value="KGS"
          className={classes.currencyItem}
          style={{ borderRadius: '5px' }}
        >
          COM
        </ToggleButton>
        <ToggleButton
          value="USD"
          variant="text"
          className={classes.currencyItem}
          style={{ borderRadius: '5px' }}
        >
          USD
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );

  const categoryHasParams =
    isCategoryParamsLoading || categoryParams?.length > 0;

  const submitButton = (
    <LoadingButton
      variant="contained"
      color="primary"
      style={{ borderRadius: '10px', fontWeight: 500 }}
      onClick={onSubmit}
      loading={isProductListLoading}
      disabled={isDisabled}
    >
      {intl.formatMessage({ ...messages.show })}
    </LoadingButton>
  );

  const resetButton = (
    <Button
      color="primary"
      variant="contained"
      className={classes.resetBtn}
      style={{ borderRadius: '10px', fontWeight: 500 }}
      onClick={onReset}
    >
      {intl.formatMessage({ ...messages.reset })}
    </Button>
  );

  const categoryFilterProps = {
    options: filters.options,
    onOptionClick: handleCategoryOptionClick,
    parameters: filters.parameters,
    onParameterUpdate: handleCategoryParameterUpdate,
    isLoading: isCategoryParamsLoading,
  };

  const getProps = (filter) => {
    let props = {
      filter,
    };

    if (filter.type === 'select') {
      props = {
        ...props,
        options: filters.options,
        onOptionClick: handleCategoryOptionClick,
      };
    } else {
      props = {
        ...props,
        parameters: filters.parameters,
        onParameterUpdate: handleCategoryParameterUpdate,
      };
    }
    return props;
  };

  if (isCategoryParamsLoading) {
    return <Skeleton />;
  }
  return (
    <Paper
      elevation={0}
      className={classes.root}
    >
      <div className={classes.container}>
        <div className={classes.filters}>
          <div
            className={classes.filter}
            style={{
              width: showAll
                ? `calc(33.33% - ${theme.spacing(1)}px)`
                : `calc(23% - ${theme.spacing(1)}px)`,
            }}
          >
            {categoryHasParams ? (
              <CategoryFilter {...getProps(categoryParams[0])} />
            ) : (
              locationsFilter
            )}
          </div>
          <div
            className={classes.filter}
            style={{
              width: showAll
                ? `calc(33.33% - ${theme.spacing(1)}px)`
                : `calc(23% - ${theme.spacing(1)}px)`,
            }}
          >
            {categoryParams?.length > 1 ? (
              <CategoryFilter {...getProps(categoryParams[1])} />
            ) : (
              orderFilter
            )}
          </div>
          <div
            className={classes.filter}
            style={{
              width: showAll
                ? `calc(33.33% - ${theme.spacing(1)}px)`
                : `calc(24% - ${theme.spacing(1)}px)`,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <TextField
                margin="dense"
                variant="outlined"
                autoComplete="off"
                name="gte"
                value={filters.gte}
                onChange={onChange}
                placeholder={`${intl.formatMessage({
                  ...messages.priceFrom,
                })}`}
                onBlur={onBlur}
                className={classes.priceFrom}
              />
              <TextField
                margin="dense"
                variant="outlined"
                autoComplete="off"
                name="lte"
                value={filters.lte}
                onChange={onChange}
                placeholder={intl.formatMessage({ ...messages.priceTo })}
                onBlur={onBlur}
                className={classes.priceTo}
              />
            </div>
          </div>
          {!showAll && (
            <>
              <div
                className={classes.filter}
                style={{
                  width: `calc(15% - ${theme.spacing(1)}px)`,
                }}
              >
                {currencyFilter}
              </div>
              <div
                className={classes.filter}
                style={{
                  width: `calc(15% - ${theme.spacing(3)}px)`,
                }}
              >
                {submitButton}
              </div>
              {!showAll && (
                <div
                  className={classes.filter}
                  style={{
                    width: `calc(15% - ${theme.spacing(3)}px)`,
                  }}
                >
                  {React.cloneElement(resetButton, {
                    style: {
                      minWidth: '88px',
                      borderRadius: '10px',
                    },
                  })}
                </div>
              )}
            </>
          )}
        </div>

        {/* Category Params */}
        <Collapse in={showAll}>
          <CategoryFilters
            filters={filtered}
            currencyFilter={currencyFilter}
            submitButton={submitButton}
            resetButton={resetButton}
            intl={intl}
            {...categoryFilterProps}
          />
        </Collapse>

        {categoryParams?.length > 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '3',
            }}
          >
            <Button
              className={classes.showAllBtn}
              endIcon={showAll ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              onClick={() => setShowAll((prevState) => !prevState)}
            >
              {showAll
                ? intl.formatMessage({ ...messages.hide })
                : intl.formatMessage({ ...messages.allParams })}
            </Button>
          </div>
        )}
      </div>
    </Paper>
  );
}

FilterComponent.propTypes = {
  values: PropTypes.object.isRequired,
  categoryParams: PropTypes.arrayOf(PropTypes.object),
  categoryParamsLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  locationsObj: PropTypes.object,
  intl: intlShape,
};

export default compose(memo, injectIntl)(FilterComponent);
