import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { searchActions, searchSelectors } from 'states/Search';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { DrawerHeader, SubmitButton } from './components';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  textField: {
    width: 'calc(50% - 3px)',
    borderRadius: '6px',

    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },

    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 6px) scale(0.75)',
    },

    '& input': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },

    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
  },
  autocompleteBox: {
    width: 'calc(50% - 3px)',

    '& .MuiAutocomplete-popper': {
      // height: 140,
      // overflow: 'hidden'
    },

    '& .MuiAutocomplete-paper': {
      // height: 140
    },
  },
  autocomplete: {
    '& .MuiInputBase-root': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderRadius: '6px',
    },
  },
  popperGroupUl: {
    height: 140,
    overflowY: 'auto',
  },
}));

function FromTo({ openedParam, onClose, intl }) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const parameters = useSelector(searchSelectors.parameters);
  const idx = parameters.findIndex((param) => openedParam?.id === param?.id);

  const [from, setFrom] = useState(idx !== -1 ? parameters[idx].from : '');
  const [to, setTo] = useState(idx !== -1 ? parameters[idx].to : '');

  const handleClear = () => {
    setFrom('');
    setTo('');
  };

  const handleSubmit = () => {
    // if (!to.trim().length > 0 || !from.trim().length > 0) return

    const { id } = openedParam;
    const params =
      idx === -1
        ? parameters
        : [...parameters.slice(0, idx), ...parameters.slice(idx + 1)];

    dispatch(
      searchActions.changeParametersAsync(
        [
          ...params,
          {
            id,
            from,
            to,
            type: 'enter',
          },
        ].filter((param) => param.from.length > 0 || param.to.length > 0),
      ),
    );
    onClose();
  };

  const handleChangeFrom = (e) => setFrom(e.target.value || '');
  const handleChangeTo = (e) => setTo(e.target.value || '');

  const options = openedParam?.options.map(
    (opt) => opt[`title_${intl.locale}`],
  );

  return (
    <>
      <DrawerHeader
        title={openedParam[`title_${intl.locale}`]}
        onClose={onClose}
        onClear={handleClear}
        intl={intl}
      />
      <div className={classes.container}>
        {openedParam?.from_to ? (
          <>
            <div className={classes.autocompleteBox}>
              <Autocomplete
                disablePortal
                className={classes.autocomplete}
                options={options}
                classes={{ listbox: classes.popperGroupUl }}
                onChange={(_, value) => handleChangeFrom({ target: { value } })}
                value={from}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="number"
                    value={from}
                    onChange={handleChangeFrom}
                    variant="filled"
                    className={classes.textField}
                    style={{ width: '100%' }}
                    label="От"
                  />
                )}
              />
            </div>

            <div className={classes.autocompleteBox}>
              <Autocomplete
                disablePortal
                className={classes.autocomplete}
                options={options}
                classes={{ listbox: classes.popperGroupUl }}
                value={to}
                onChange={(_, value) => handleChangeTo({ target: { value } })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="number"
                    value={to}
                    onChange={handleChangeTo}
                    variant="filled"
                    className={classes.textField}
                    style={{ width: '100%' }}
                    label="До"
                  />
                )}
              />
            </div>
          </>
        ) : (
          <>
            <TextField
              value={from}
              className={classes.textField}
              onChange={(e) => setFrom(e.target.value)}
              variant="filled"
              type="number"
              placeholder="от"
            />
            <TextField
              value={to}
              className={classes.textField}
              onChange={(e) => setTo(e.target.value)}
              variant="filled"
              type="number"
              placeholder="до"
            />
          </>
        )}
      </div>
      HUIHUIHIUI
      <SubmitButton onSubmit={handleSubmit} />
    </>
  );
}

FromTo.propTypes = {
  openedParam: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape,
};

export default FromTo;
