import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router-dom';

import AuthFormComponent from 'components/AuthForm';
import ConfirmPhoneComponent from 'components/AuthForm/ConfirmPhone';

import messages from 'messages/Auth';

import { authActions } from 'services/auth';

function AuthFormContainer(props) {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { isOpen, onClose, defaultType = 'login', intl } = props;

  const [isUserConfirm, setUserConfirm] = useState(false);

  const login = useCallback(
    async (params) => {
      try {
        await dispatch(authActions.login(params));
        onClose();
      } catch (e) {
        if (params?.password?.length < 6 || params?.login.trim()?.length < 6) {
          alert.error(
            intl.locale === 'ru' ? 'Заполните форму' : 'Форманы толтуруңуз',
          );
        } else {
          alert.error(intl.formatMessage({ ...messages.authFail }));
        }
      }
    },
    [dispatch, onClose, alert, intl],
  );

  const confirmPhone = async (code) => {
    try {
      await dispatch(
        authActions.activate({
          code: +code.join(''),
        }),
      );
      setUserConfirm(false);
      return true;
    } catch (e) {
      if (
        e?.non_field_errors &&
        e.non_field_errors?.[0]?.message ===
          'Incorrect values or user has been activated'
      ) {
        alert.error(intl.formatMessage({ ...messages.invalidCode }));
      }
      return false;
    }
  };

  const handleRegister = async (data) => {
    try {
      await dispatch(authActions.register(data));
      setUserConfirm(true);
    } catch (e) {
      console.warn(e);
      if (
        (e.non_field_errors && e.non_field_errors[0].code === 'user_exists') ||
        /user already exists, please sign in/.test(e.message)
      ) {
        if (data?.login?.includes('+996')) {
          alert.error(intl.formatMessage({ ...messages.userExists }));
        } else {
          alert.error(intl.formatMessage({ ...messages.emailAlreadyInUse }));
        }

        setUserConfirm(false);
      } else if (/you must activate/.test(e?.message)) {
        await dispatch(authActions.sendCode({ login: data?.login }));
        setUserConfirm(true);
      } else if (
        e?.login?.[0]?.code === 'blank' ||
        e?.name?.[0]?.code === 'blank'
      ) {
        alert.error(
          intl.locale === 'ru' ? 'Заполните форму' : 'Форманы толтуруңуз',
        );
      } else if (/Incorrect value for login./.test(e?.message)) {
        alert.error(intl.formatMessage({ ...messages.authFail }));
      } else {
        alert.error(intl.formatMessage({ ...messages.error }));
        setUserConfirm(false);
      }
    }
  };

  return (
    <>
      {isUserConfirm ? (
        <ConfirmPhoneComponent
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setUserConfirm(false);
          }}
          onSubmit={confirmPhone}
          setUserConfirm={setUserConfirm}
          isPasswordRecovery={false}
        />
      ) : (
        <AuthFormComponent
          isOpen={isOpen}
          onClose={onClose}
          defaultType={defaultType}
          onLogin={login}
          onRegister={handleRegister}
          confirmPhone={confirmPhone}
        />
      )}
      <input
        style={{ display: 'none' }}
        id="register-recaptcha-container"
        type="button"
      />
    </>
  );
}

AuthFormContainer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  defaultType: PropTypes.oneOf(['login', 'register']),
  intl: intlShape,
};

export default compose(memo, injectIntl)(AuthFormContainer);
