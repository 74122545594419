import React, {
  memo,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

// MUI
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import {
  Typography,
  Grid,
  Collapse,
  Paper,
  Box,
  ListItem,
  Tooltip,
} from '@material-ui/core';

import MenuIcon from '../components/MenuIcon';
import AddTaskIcon from '@material-ui/icons/AddToHomeScreen';
import { Clear } from '@material-ui/icons';

// Core
import { useHistory } from 'react-router';
import SubCategoryList from '../components/SubCategoryList';
import { useStyles } from './styles';
import { UserContext } from '../../../providers/UserProvider';
import { NavLink } from 'react-router-dom';
import { paths } from 'common/constants';
import Tips from '../components/Tips';
import { useOutsideClick } from 'hooks/useOutSideClick';
import { useDispatch } from 'react-redux';
import { productActions } from 'states/Products';

function hasParentElementWithAttr(el, attr, attrVal, breakpointNode) {
  if (el.getAttribute(attr) === attrVal) {
    return true;
  }
  if (el.isSameNode(breakpointNode)) {
    return false;
  }
  return (
    el.parentNode &&
    hasParentElementWithAttr(el.parentNode, attr, attrVal, breakpointNode)
  );
}

const isScrollable = function (ele) {
  // Compare the height to see if the element has scrollable content
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  // It's not enough because the element's `overflow-y` style can be set as
  // * `hidden`
  // * `hidden !important`
  // In those cases, the scrollbar isn't shown
  const overflowYStyle = window.getComputedStyle(ele).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

function Desktop(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    onSubmit,
    intl,
    messages,
    categories,
    debounce,
    handleShowTips,
    showTips,
    onChangeSearch,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [listOpen, setListOpen] = useState(null);
  const [selected, setSelected] = useState(categories?.[0]);
  const [categoryListBottomReached, setCategoryListBottomReached] =
    useState(false);
  const [categoryItemBottomReached, setCategoryItemBottomReached] =
    useState(false);

  const [isSearching, setIsSearching] = useState(false);

  const { user, openAuthForm } = useContext(UserContext);
  const rootRef = useRef();
  const categoryMenuPaperRef = useRef();
  const categoryListRef = useRef();
  const categoryItemRef = useRef();
  const tipsListRef = useRef(null);

  const handleRaiseAdBtn = () => {
    if (!user) {
      openAuthForm('login');
    } else {
      history.push('/post');
    }
  };

  const makePageScrollable = () => {
    const scroller = document.getElementById('scroller');

    if (scroller) {
      scroller.style.overflowY = 'auto';
    }
  };

  const handleCategoryListWheel = (e) => {
    if (!categoryListRef.current) return;

    if (e.deltaY < 0) {
      return categoryListBottomReached && setCategoryListBottomReached(false);
    }

    const { scrollTop, scrollHeight, clientHeight } = categoryListRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      if (!categoryListBottomReached) {
        isScrollable(categoryListRef.current)
          ? setTimeout(() => setCategoryListBottomReached(true), 500)
          : setCategoryListBottomReached(true);
      } else {
        setListOpen(false);
        makePageScrollable();
      }
    }
  };

  const handleClearSearch = async () => {
    setIsSearching(false);
    await onChange({ target: { value: '' } });
    onSubmit(' ');
  };

  const handleCategoryItemWheel = (e) => {
    if (!categoryItemRef.current) return;

    if (e.deltaY < 0) {
      return setCategoryItemBottomReached(false);
    }

    if (
      hasParentElementWithAttr(
        e.target,
        'data-component-type',
        'scrollable',
        categoryItemRef.current,
      )
    ) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = categoryItemRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      if (!categoryItemBottomReached) {
        isScrollable(categoryItemRef.current)
          ? setTimeout(() => setCategoryItemBottomReached(true), 500)
          : setCategoryItemBottomReached(true);
      } else {
        setListOpen(false);
        makePageScrollable();
      }
    }
  };

  const clearState = () => {
    if (categoryListRef.current) {
      categoryListRef.current.scrollTo(0, 0);
    }
    if (categoryItemRef.current) {
      categoryItemRef.current.scrollTo(0, 0);
    }

    setCategoryListBottomReached(false);
    setCategoryItemBottomReached(false);
  };

  const toggleListOpen = () =>
    setListOpen((prevState) => {
      clearState();
      const scroller = document.getElementById('scroller');

      if (scroller) {
        scroller.style.overflowY = prevState ? 'auto' : 'hidden';
      }

      const veiwPortBottom = window.scrollY + window.innerHeight;

      const footerRect = document
        .getElementById('footer')
        ?.getBoundingClientRect();

      const rootRect = rootRef.current?.getBoundingClientRect();

      const height =
        window.scrollY + window.innerHeight - rootRect.bottom + 'px';

      if (!rootRect && !footerRect) return;

      const heightWithoutProducts = footerRect.top - rootRect.bottom + 'px';

      if (!categoryMenuPaperRef.current) return;

      if (footerRect.top - veiwPortBottom >= 0) {
        categoryMenuPaperRef.current.style.height = height;
        return !prevState;
      }

      categoryMenuPaperRef.current.style.height = heightWithoutProducts;

      return !prevState;
    });

  const clearButton = useMemo(() => {
    if (!value) return null;

    return (
      <IconButton
        className={classes.closeButton}
        onClick={handleClearSearch}
      >
        <Clear />
      </IconButton>
    );
  }, [value, debounce]);

  useEffect(() => {
    handleShowTips(debounce);
    setIsSearching(true);
  }, [debounce]);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  useOutsideClick(tipsListRef, () => setIsSearching(false), isSearching);

  return (
    <div className={classes.searching}>
      <Collapse
        in={true}
        className={classes.searchCollapse}
      >
        <div
          ref={rootRef}
          className={classes.root}
        >
          <div className={classes.searchWrap}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.menuButton}
              endIcon={
                <MenuIcon
                  open={listOpen}
                  classes={classes}
                  animate
                />
              }
              onClick={toggleListOpen}
            >
              {intl.formatMessage({ ...messages.allCategories })}
            </Button>
            <form
              style={{ flexGrow: 3, maxWidth: '460px' }}
              onSubmit={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/',
                });
                onSubmit(value);
              }}
            >
              <TextField
                variant="outlined"
                value={value}
                onFocus={() => {
                  setTimeout(() => setIsSearching(true), 100);
                }}
                onChange={(e) => {
                  onChange(e);
                }}
                className={classes.textfield}
                placeholder={intl.formatMessage({
                  ...messages.search,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.searchAdornment}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: 'none',
                        }}
                        // disableelevation
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: '/',
                          });
                          onSubmit(value);
                        }}
                      >
                        {intl.formatMessage({ ...messages.find })}
                      </Button>
                      {clearButton}
                    </InputAdornment>
                  ),
                  classes: {
                    root: classes.searchPropInput,
                    focused: classes.searchFieldFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </form>
            <Button
              onClick={handleRaiseAdBtn}
              variant="contained"
              color="primary"
              className={classes.postBtn}
            >
              <Typography noWrap>
                {intl.formatMessage({ ...messages.postButton })}
              </Typography>
            </Button>
          </div>
          <Collapse
            in={listOpen}
            className={classes.categoryCollapse}
            id="category-menu"
          >
            <Paper
              ref={categoryMenuPaperRef}
              elevation={0}
              id="category-menu-paper"
            >
              <div
                className={classes.container}
                style={{
                  height: '100%',
                }}
              >
                <Grid
                  container
                  style={{
                    height: '100%',
                  }}
                >
                  {/* CategoryList */}
                  <Grid
                    item
                    md={3}
                    style={{ height: '100%' }}
                  >
                    <Box
                      borderRight="1px solid #CCCCCC"
                      padding="12px"
                      height="inherit"
                      overflow="auto"
                      onWheel={handleCategoryListWheel}
                      ref={categoryListRef}
                    >
                      {categories?.map((cat) => (
                        <ListItem
                          button
                          key={cat.id}
                          selected={cat.id === selected?.id}
                          starticon={<AddTaskIcon />}
                          onMouseEnter={() => setSelected(cat)}
                          className={classes.mainCategory}
                          component={NavLink}
                          to={paths.CATEGORY_PAGE(cat.title_slug)}
                          onClick={() => {
                            toggleListOpen();
                            onChange({ target: { value: '' } });
                            dispatch(productActions.clearProductListAsync());
                          }}
                        >
                          {cat[`title_${intl.locale}`]}
                        </ListItem>
                      ))}
                    </Box>
                  </Grid>
                  {/* CategoryItem */}
                  <Grid
                    item
                    md
                    style={{ height: '100%' }}
                  >
                    <Box
                      padding="20px"
                      overflow="auto"
                      height="inherit"
                      onWheel={handleCategoryItemWheel}
                      ref={categoryItemRef}
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        style={{ fontWeight: 'bold' }}
                      >
                        {selected?.[`title_${intl.locale}`]}
                      </Typography>

                      <Box
                        display="flex"
                        flexWrap="wrap"
                        justifyContent="flex-start"
                      >
                        {selected?.children?.map((cat) => (
                          <SubCategoryList
                            key={cat.id}
                            cat={cat}
                            intl={intl}
                            onClick={toggleListOpen}
                            onChange={onChange}
                            classes={classes}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Collapse>
        </div>
        {isSearching && (
          <Box ref={tipsListRef}>
            {showTips?.results?.length && debounce !== '' ? (
              <Tips
                tips={showTips?.results}
                debounce={debounce}
                onSubmit={onSubmit}
                onChangeSearch={onChangeSearch}
              />
            ) : (
              showTips?.results?.length === 0 &&
              debounce !== '' && (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Add"
                >
                  <Tips
                    debounce={debounce}
                    onSubmit={onSubmit}
                  />
                </Tooltip>
              )
            )}
          </Box>
        )}
      </Collapse>
    </div>
  );
}

Desktop.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  messages: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  isMouseNearOrScrolling: PropTypes.bool,
};

export default memo(Desktop);
