import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: 196,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-41%, 0)',
    top: '80%',
    borderRadius: '8px',
    backgroundColor: 'white',
    boxShadow: ' 0px 0px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 9999,
  },
  tip: {
    width: '100%',
    overflow: 'hidden',
    height: '30px',
    fontSize: '16px',
    color: 'black',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '5px',
    '&:hover': {
      background: '#EBEBEB',
      cursor: 'pointer',
    },
  },
}));
