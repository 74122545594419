import { createActions } from 'redux-actions';
import { ACTION_TYPES, ENDPOINTS } from './constants';
import { createRequestAction } from '../../common/helpers';

const actionCreators = createActions({
  ...createRequestAction(ACTION_TYPES.SEND_REQUEST),
  ...createRequestAction(ACTION_TYPES.SEND_LOGO),
  ...createRequestAction(ACTION_TYPES.GET_PROFILE),
  ...createRequestAction(ACTION_TYPES.SEND_CONTACTS),
  ...createRequestAction(ACTION_TYPES.SEND_SCHEDULES),
  ...createRequestAction(ACTION_TYPES.SEND_CATALOG),
  ...createRequestAction(ACTION_TYPES.SEND_DELIVERY),
  ...createRequestAction(ACTION_TYPES.GET_DELIVERY),
  ...createRequestAction(ACTION_TYPES.CREATE_DELIVERY),
  ...createRequestAction(ACTION_TYPES.SEND_BANNERS),
  ...createRequestAction(ACTION_TYPES.DELETE_BANNER),
  ...createRequestAction(ACTION_TYPES.SEND_PRODUCT_TO_CATALOG),
  ...createRequestAction(ACTION_TYPES.GET_CATALOGS),
  ...createRequestAction(ACTION_TYPES.REMOVE_PRODUCTS_FROM_CATALOG),
  ...createRequestAction(ACTION_TYPES.GET_BUSINESS_PROFILE),
  ...createRequestAction(ACTION_TYPES.UPDATE_BUSINESS_PROFILE),
  ...createRequestAction(ACTION_TYPES.GET_REVIEWS),
  ...createRequestAction(ACTION_TYPES.SEND_REVIEWS),
  ...createRequestAction(ACTION_TYPES.GET_BUSINESS_REVIEWS),
  ...createRequestAction(ACTION_TYPES.SEND_ANSWER),
  ...createRequestAction(ACTION_TYPES.SEND_CLAIM_TO_REVIEW),
  ...createRequestAction(ACTION_TYPES.SEND_STOCK_PRODUCTS),
  ...createRequestAction(ACTION_TYPES.FETCH_PRODUCT_STATS),
  ...createRequestAction(ACTION_TYPES.FETCH_PRODUCT_STATS_BY_DATE),
  ...createRequestAction(ACTION_TYPES.FETCH_ALL_STATS),
  ...createRequestAction(ACTION_TYPES.UP_AND_PURCHASE_BALANCE),
  ...createRequestAction(ACTION_TYPES.PAYMENT_HISTORY),
  ...createRequestAction(ACTION_TYPES.FETCH_TARIFFS),
  ...createRequestAction(ACTION_TYPES.PURCHASE_BUSINESS),
  ...createRequestAction(ACTION_TYPES.CHANGE_CATALOG),
});

export const sendRequest = (info) => {
  return async (dispatch, getState, { api }) => {
    try {
      const res = await api.post(ENDPOINTS.REQUEST, info);
      await dispatch(actionCreators.sendRequestSuccess(res));
    } catch (e) {
      dispatch(actionCreators.sendRequestFail(e.response.data.name));
      return e.response.data.name;
    }
  };
};

export const sendBusinessProfile = (info) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.patch(ENDPOINTS.UPDATE_BUSINESS_PROFILE, info);
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
};

export const sendLogoOrBanner = (logo) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.patch(ENDPOINTS.PROFILE, logo);
      dispatch(actionCreators.sendLogoSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendLogoFail());
    }
  };
};

export const sendContacts = (contacts) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.CONTACTS, contacts);
      dispatch(actionCreators.sendContactsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendContactsFail(e));
    }
  };
};

export const sendSchedules = (schedules) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.SCHEDULES, schedules);
      dispatch(actionCreators.sendSchedulesSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendSchedulesFail(e));
    }
  };
};

export const sendCatalog = (id, name) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.CATALOG(id), name);

      dispatch(actionCreators.sendCatalogSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendCatalogFail(e));
    }
  };
};

export const sendDelivery = (businessId, delivery) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.patch(
        ENDPOINTS.DELIVERY(businessId),
        delivery,
      );

      dispatch(actionCreators.sendDeliverySuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendDeliveryFail(e));
      return e;
    }
  };
};

export const createDelivery = (delivery) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.DELIVERY_CREATE, delivery);

      dispatch(actionCreators.createDeliverySuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.createDeliveryFail(e));
    }
  };
};

export const getDelivery = (businessId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.DELIVERY(businessId));

      dispatch(actionCreators.getDeliverySuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.getDeliveryFail(e));
    }
  };
};

export const sendBanner = (banner) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.BANNERS, banner);

      dispatch(actionCreators.sendBannersSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendBannersFail(e));
    }
  };
};

export const deleteBanner = (id) => {
  return async (dispatch, getState, { api }) => {
    try {
      return await api.delete(ENDPOINTS.DELETE_BANNER(id));
    } catch (e) {
      dispatch(actionCreators.deleteBannerFail(e));
    }
  };
};

export const sendProductToCatalog = (businessId, catalogId, product) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.patch(
        ENDPOINTS.PRODUCT_TO_CATALOG(businessId, catalogId),
        { products: product },
      );

      dispatch(actionCreators.sendProductToCatalogSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendProductToCatalogFail(e));
    }
  };
};

export const removeProductsFromCatalog = (catalogId, products) => {
  return async (dispatch, getState, { api }) => {
    try {
      const response = await api.delete(
        ENDPOINTS.REMOVE_PRODUCTS_FROM_CATALOG(catalogId),
        {
          data: { product_ids: products },
        },
      );
      const statusCode = response.status;
      dispatch(actionCreators.removeProductsFromCatalogSuccess(response.data));
      return statusCode;
    } catch (e) {
      const statusCode = e.response ? e.response.status : null;
      dispatch(actionCreators.removeProductsFromCatalogFail(e));
      return statusCode;
    }
  };
};

export const fetchCatalogs = (businessId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.CATALOG(businessId));

      dispatch(actionCreators.getCatalogsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.getCatalogsFail(e));
    }
  };
};

export const deleteCatalog = (businessId, id) => {
  return async (dispatch, getState, { api }) => {
    try {
      return await api.delete(ENDPOINTS.PRODUCT_TO_CATALOG(businessId, id));
    } catch (e) {
      dispatch(actionCreators.deleteBannerFail(e));
    }
  };
};

export const getBusiness = (businessId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(`${ENDPOINTS.BUSINESS}${businessId}`);

      return data;
    } catch (e) {
      dispatch(actionCreators.getBusinessProfileFail(e));
    }
  };
};

export const getBusinessShort = (businessId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(
        `${ENDPOINTS.BUSINESS_SHORT}${businessId}`,
      );

      return data;
    } catch (e) {
      dispatch(actionCreators.getBusinessProfileFail(e));
    }
  };
};

export const fetchProductReviews = (productId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.PRODUCT_REVIEWS(productId));

      dispatch(actionCreators.getReviewsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.getReviewsFail(e));
    }
  };
};

export const sendReview = (productId, review) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        ENDPOINTS.PRODUCT_REVIEWS(productId),
        review,
      );

      dispatch(actionCreators.sendReviewsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendReviewsFail(e));
    }
  };
};

export const sendAnswer = (productId, reviewId, answer) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        ENDPOINTS.ANSWER(productId, reviewId),
        answer,
      );

      dispatch(actionCreators.sendAnswerSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendAnswerFail(e));
    }
  };
};

export const sendClaimToReview = (productId, reviewId, answer) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        ENDPOINTS.CLAIM_TO_REVIEW(productId, reviewId),
        answer,
      );

      dispatch(actionCreators.sendClaimToReviewSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendClaimToReviewFail(e));
    }
  };
};

export const sendStockProducts = (businessId, stocks) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        ENDPOINTS.STOCK_PRODUCTS(businessId),
        stocks,
      );

      dispatch(actionCreators.sendStockProductsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendStockProductsFail(e));
    }
  };
};

export const fetchStatsForProduct = (productId, start, end) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.PRODUCT_STATS(productId), {
        params: { start, end },
      });

      dispatch(actionCreators.fetchProductStatsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.fetchProductStatsFail(e));
    }
  };
};

export const fetchStatsByDate = (productId, startDate, endDate, forProduct) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(
        `${
          forProduct ? ENDPOINTS.PRODUCT_STATS(productId) : ENDPOINTS.STATS
        }/?start=${startDate}&end=${endDate}`,
      );

      dispatch(actionCreators.fetchProductStatsByDateSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.fetchProductStatsByDateFail(e));
    }
  };
};

export const fetchAllStats = (businessId, start, end) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.STATS_ALL(businessId), {
        params: {
          start,
          end,
        },
      });

      dispatch(actionCreators.fetchAllStatsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.fetchAllStatsFail(e));
    }
  };
};

export const upAndPurchaseBalance = (object) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(ENDPOINTS.PAYMENT, object);

      dispatch(actionCreators.upAndPurchaseBalanceSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.upAndPurchaseBalanceFail(e));
    }
  };
};

export const fetchPaymentHistory = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.PAYMENT_HISTORY);

      dispatch(actionCreators.paymentHistorySuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.paymentHistoryFail(e));
    }
  };
};

export const fetchTariffs = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.TARIFFS);

      dispatch(actionCreators.fetchTariffsSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.fetchTariffsFail(e));
    }
  };
};

export const purchaseBusiness = (type, tariff) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        type === 'wallet'
          ? ENDPOINTS.PURCHASE_WITH_WALLET
          : ENDPOINTS.PURCHASE_WITH_PAYBOX,
        tariff,
      );

      dispatch(actionCreators.purchaseBusinessSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.purchaseBusinessFail(e));
    }
  };
};

export const changeCatalog = (name, businessId, catalogId) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.patch(
        ENDPOINTS.CHANGE_CATALOG(businessId, catalogId),
        name,
      );

      dispatch(actionCreators.changeCatalogSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.changeCatalogFail(e));
    }
  };
};

export const actions = {
  ...actionCreators,
  sendRequest,
  sendBusinessProfile,
  sendLogoOrBanner,
  sendContacts,
  sendSchedules,
  sendCatalog,
  sendDelivery,
  getDelivery,
  createDelivery,
  sendBanner,
  deleteBanner,
  sendProductToCatalog,
  removeProductsFromCatalog,
  fetchCatalogs,
  deleteCatalog,
  getBusiness,
  getBusinessShort,
  fetchProductReviews,
  sendReview,
  sendAnswer,
  sendClaimToReview,
  sendStockProducts,
  fetchStatsForProduct,
  fetchStatsByDate,
  fetchAllStats,
  upAndPurchaseBalance,
  fetchPaymentHistory,
  fetchTariffs,
  purchaseBusiness,
  changeCatalog,
};
