import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  menuBtn: {
    marginRight: theme.spacing(0.75),
    maxHeight: '40px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  textfield: {
    width: '100%',
    boxShadow:
      '0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)',
    borderRadius: 5,
    height: 40,
    margin: theme.spacing(2, 'auto'),
    backgroundColor: theme.palette.primary.light,

    '& .MuiInputBase-input': {
      fontSize: '12px',
    },

    '& .MuiOutlinedInput-root': {
      height: 'inherit',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      paddingRight: 20,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },

  closeButton: {
    position: 'absolute',
    width: 35,
    height: '35px !important',
    right: 47,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50% !important',
  },

  searchResult: {
    position: 'absolute',
    top: '80%',
    left: 16,
    right: 16,
    zIndex: 99,
  },

  filterMenuBtn: {
    maxHeight: '40px',
    minWidth: '50px',
    marginLeft: theme.spacing(0.75),
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.light,
  },

  searchIcon: {
    borderRadius: '0',
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    fontSize: '30px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    padding: '6px 11px',

    '&:hover': {
      color: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '29px',
    },
  },

  menuDrawer: {
    '&>.MuiPaper-root': {
      width: '101%',
      backgroundColor: theme.palette.primary.light,
      overflowX: 'hidden',
      paddingBottom: theme.spacing(10),
    },
  },
  menuClose: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  menuItem: {
    // padding: 0
  },
  searchPropInput: {
    '&$searchFieldFocused $notchedOutline': {
      border: 'none',
      borderWidth: 0,
    },
    '&:hover $notchedOutline': {
      border: 'none',
    },
  },
  notchedOutline: {
    border: 'none',
    borderWidth: 0,
  },
  searchFieldFocused: {},
  searchAdornment: {
    height: 45,
  },
  filterButton: {
    padding: 0,
    width: 50,
    height: 50,
    margin: 0,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
    borderRadius: 5,
  },
  activeFilterButton: {
    background: '#4E89FA',
    '&:hover': {
      background: '#4E89FA',
    },
  },

  // filter
  paper: {
    padding: 10,
    boxShadow:
      '0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)',
    width: '100%',
    height: '100vh',
    position: 'relative',

    '& label': {
      fontWeight: 500,
      fontSize: 14,
      color: '#4B4B4B',
    },
    '& .MuiSelect-root .MuiListItemIcon-root, .MuiSelect-root .MuiSvgIcon-root':
      {
        display: 'none',
      },
    '& .MuiSelect-root .MuiListItemText-root': {
      marginBottom: 0,
      marginTop: 0,

      '& .MuiListItemText-primary': {
        lineHeight: 'inherit',
      },
    },
    '& .MuiOutlinedInput-root': {
      width: 250,
    },
    '& .MuiFilledInput-root': {
      height: 30,
      borderRadius: 5,
      padding: 0,

      '& .MuiFilledInput-inputMarginDense': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '& .MuiFilledInput-root::before, .MuiFilledInput-root::after': {
      display: 'none',
    },
  },
  textPrimary: {
    marginRight: 10,
  },
  productCount: {
    color: '#8E8E93',
    fontSize: 12,
    fontWeight: 500,
  },
  currency: {
    display: 'block',
    marginTop: 10,

    '& button': {
      height: 36,
      width: '50%',
    },
  },
  actions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    bottom: 80,
    left: 0,
    right: 0,

    '& .MuiButton-contained.Mui-disabled': {
      background: '#E1E1E1',
      color: '#FFF',
    },
    '& button': {
      height: 40,
      borderRadius: 10,
      padding: '2px 25px',
      textTransform: 'inherit',
      width: '48%',

      '&:first-child': {
        borderColor: theme.palette.primary.contrastText,
        color: `${theme.palette.primary.contrastText} !important`,
      },
    },
  },
  navigateNextIcon: {
    fill: '#B5B5B5',
  },
  orderSelect: {
    width: '100% !important',
    '& fieldset': {
      border: '1px solid #A7A7A7 !important',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  filterTitle: {
    color: '#4B4B4B',
    fontSize: 18,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  closeIcon: {
    position: 'absolute',
    top: 3,
    left: 0,
    '& svg': {
      fill: theme.palette.primary.blue,
    },
  },
}));
