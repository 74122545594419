export const COLORS = {
  BLACK: '#0A142F',
  BLACK_BLUE: '#00047A',
  BLUE: '#0051BA',
  GRAY: '#5C5C5C',
  LIGHT_GRAY: '#EDEDED',
  GRAY_100: '#EAEAEA',
  GRAY_400: '#B1B1B1',
  SLATE_BLUE_GRAY: '#9AA6B6',
  WHITE: '#FFFFFF',
  LIGHT_BLUE: '#D5E1F1',
  RED: '#FF4D4D',
  RED_DARK: '#FF0000',
  SEPARATE_GRAY: '#CDCDCD',
  YELLOW: '#FFCB05',
  GREEN: '#04A027',
  ORANGE: '#F57C00',
};
export const MEDIA = {
  LARGE_DESKTOP: 1440,
  DESKTOP: 1020,
  TABLET: 722,
  MOBILE_TO_TABLE: 692,
  MOBILE: 480,
  SMALL_MOBILE: 342,
};

export const BUSINESS_ENDPOINTS = 'business/v2';
