import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ScrollMemory from 'react-router-scroll-memory';
import { StickyContainer } from 'react-sticky';

// Providers
import LanguageProvider from 'providers/LanguageProvider';
import AppProvider from 'providers/AppProvider';
import UserProvider from 'providers/UserProvider';
import FirebaseProvider from 'providers/FirebaseProvider';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';

// Utils
import history from 'utils/history';
import AlertTemplate from 'components/Alert';
import { routes } from './routes';
import RouteWithLayout from './components/RouteWithLayout';
import PrivateRouteWithLayout from './components/PrivateRouteWithLayout';
import ScrollToTop from './components/ScrollToTop';
import Header from './containers/Header';
import createStoreRedux from './configureStore';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';

// Import i18n messages
import { translationMessages } from './i18n';

// Theme
import MuiTheme from './theme';

// Drag and drop initialize
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Create redux store with history
const initialState = {};
export const store = createStoreRedux(initialState, history);

const useStyles = makeStyles((theme) => ({
  banner: {
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100vh',
    background: '#ffffff',
    backgroundSize: 'contain',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: 6,
    },

    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },

    '&::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: 5,
    },

    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
    '@media (max-width: 920px)': {
      background: '#FFFFFF',
    },
  },
  container: {
    background: '#fafafa',
    '@media (max-width: 1125px)': {
      padding: '0',
    },
  },
  progress: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    paddingTop: 200,
  },
  centered: {
    '& input, button, textarea': {
      outline: 'none',
    },
    background: '#fafafa',
    width: '78%',
    marginLeft: '11%',
    marginTop: '10%',
    minHeight: '100vh',
    '@media (max-width: 920px)': {
      width: '100%',
      marginLeft: '0',
      marginTop: '0',
    },
    '@media (max-width: 720px)': {
      background: '#fafafa',
    },
    '@media (min-width: 1920px)': {
      width: '70%',
      marginLeft: '15%',
    },
    '@media (min-width: 2400px)': {
      width: '50%',
      marginLeft: '25%',
    },
    '@media (min-width: 3200px)': {
      width: '40%',
      marginLeft: '30%',
    },
    '@media (min-width: 3800px)': {
      width: '30%',
      marginLeft: '35%',
    },
  },
}));

const alertOptions = {
  position: positions.TOP_RIGHT,
  transition: transitions.SCALE,
  timeout: 5000,
  offset: '15px -15px',
  containerStyle: {
    zIndex: 9999,
  },
};

function App(props) {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <MuiTheme>
          <FirebaseProvider>
            <LanguageProvider messages={translationMessages}>
              <ConnectedRouter history={history}>
                <AlertProvider
                  template={(props) => <AlertTemplate {...props} />}
                  {...alertOptions}
                >
                  <AppProvider>
                    <UserProvider>
                      <Suspense
                        fallback={
                          <div className={classes.progress}>
                            <CircularProgress />
                          </div>
                        }
                      >
                        <div>
                          <StickyContainer
                            id="scroller"
                            className={classes.banner}
                          >
                            <div
                              id="centered"
                              className={classes.centered}
                            >
                              <div id="back-to-top-anchor" />
                              <CssBaseline />
                              <ScrollMemory elementID="scroller" />
                              <Header />
                              <ScrollToTop {...props}>
                                <Fab
                                  color="primary"
                                  size="large"
                                  aria-label="scroll back to top"
                                  style={{
                                    boxShadow: 'none',
                                  }}
                                >
                                  <KeyboardArrowUpIcon />
                                </Fab>
                              </ScrollToTop>
                              <Container
                                maxWidth="md"
                                disableGutters
                                classes={{ maxWidthMd: classes.container }}
                              >
                                <HomePage />
                                <Switch>
                                  {routes.map(
                                    ({ name, secure, nested, ...rest }) => {
                                      const Route = secure
                                        ? PrivateRouteWithLayout
                                        : RouteWithLayout;

                                      return (
                                        <Route
                                          key={name}
                                          {...rest}
                                        />
                                      );
                                    },
                                  )}
                                </Switch>
                              </Container>
                              <Footer />
                            </div>
                          </StickyContainer>
                        </div>
                      </Suspense>
                    </UserProvider>
                  </AppProvider>
                </AlertProvider>
              </ConnectedRouter>
            </LanguageProvider>
          </FirebaseProvider>
        </MuiTheme>
      </DndProvider>
    </Provider>
  );
}

export default App;
