import React, { useState, useEffect, useMemo } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { TextField, InputAdornment, IconButton, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import messages from 'messages/Filter';
import { useStyles } from './styles';
import Tips from '../components/Tips';
import { Clear } from '@material-ui/icons';

function SearchField(props) {
  const {
    onChange,
    intl,
    value,
    debounce,
    handleShowTips,
    onSubmit,
    showTips,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    handleShowTips(debounce);
  }, [debounce]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClearSearch = async () => {
    await onChange({ target: { value: '' } });
    onSubmit(' ');
  };

  const clearButton = useMemo(() => {
    if (!value) return null;

    return (
      <IconButton
        className={classes.closeButton}
        onClick={handleClearSearch}
      >
        <Clear />
      </IconButton>
    );
  }, [value, debounce]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          history.push({ pathname: '/' });
          onSubmit(value);
        }}
        style={{
          display: 'inline-block',
          width: 'calc(100% - 55px)',
          flexGrow: 1,
        }}
      >
        <TextField
          aria-describedby={id}
          id="mobileInput"
          variant="outlined"
          value={value}
          onChange={(e) => {
            onChange(e);
            handleClick(e);
          }}
          className={classes.textfield}
          placeholder={intl.formatMessage({ ...messages.search })}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                className={classes.searchAdornment}
              >
                <IconButton
                  type="submit"
                  className={classes.searchIcon}
                >
                  <SearchIcon />
                </IconButton>
                {clearButton}
              </InputAdornment>
            ),
            classes: {
              root: classes.searchPropInput,
              focused: classes.searchFieldFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
        />
      </form>
      <Box className={classes.searchResult}>
        {showTips?.results?.length && debounce !== '' ? (
          <Tips
            tips={showTips?.results}
            debounce={debounce}
            onSubmit={onSubmit}
          />
        ) : (
          showTips?.results?.length === 0 &&
          debounce !== '' && (
            <Tips
              debounce={debounce}
              onSubmit={onSubmit}
            />
          )
        )}
      </Box>
    </>
  );
}

SearchField.propTypes = {
  intl: intlShape,
};

export default injectIntl(SearchField);
