import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  title: {
    color: '#333333',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  centerTitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  isBusinessBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
    alignItems: 'center',
    '& p': {
      color: theme.palette.primary.blue,
      fontSize: '16px',
      lineHeight: '19px',
      textTransform: 'uppercase',
    },
  },
  loading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0',
  },
}));
