import React, { useMemo, memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  SwipeableDrawer,
  Typography,
  Box,
  IconButton,
  Grid,
  TextField,
  Button,
  MenuItem,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import messages from 'messages/Filter';

import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { searchActions, searchSelectors } from 'states/Search';
import { getObjectLineFromTree } from 'common/helpers';
import { paths } from 'common/constants';
import { useHistory } from 'react-router';
import OptionDrawer from './components/OptionDrawer';
import { appSelectors } from '../../providers/AppProvider/data';
import CategoryFilters from './components/CategoryFilters';
import Skeleton from './components/Skeleton';
import { postActions } from '../../states/Post';
import { useAllLocations } from 'hooks/useAllLocations';
import { useCategories } from 'hooks/useCategories';
import { useAlert } from 'react-alert';

const defaultState = {
  selectedCategory: 'all',
  selectedLocation: 'all',
  order: '-upvote_date',
  gte: '',
  lte: '',
  currency: 'KGS',
  options: [],
  parameters: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 11000,

    '& .MuiPaper-root': {
      width: '101%',
      backgroundColor: '#fafafa',
      zIndex: 1300,
    },
  },
  container: {
    fontSize: 18,
    padding: theme.spacing(1),
    paddingBottom: 190,
  },
  menuClose: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '3px',
    color: theme.palette.primary.blue,
  },
  inputBox: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textField: {
    '& input': {
      fontSize: 18,
      color: theme.palette.primary.blue,
    },
    '& input::placeholder': {
      color: theme.palette.primary.blue,
      opacity: 1,
    },
  },
  btnContainer: {
    position: 'fixed',
    width: '100%',
    bottom: theme.spacing(1.8),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  btn: {
    fontSize: 14,
    textTransform: 'none',
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.light,
    '&.MuiButtonBase-root.Mui-disabled': {
      background: '#DBDBDB',
    },
  },
  resetBtn: {
    color: theme.palette.primary.contrastText,
  },
  currencyItem: {
    width: '45%',
    border: 'none',
    color: theme.palette.primary.dark,
    fontSize: 16,

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.blue,
      color: theme.palette.primary.light,
    },

    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.blue,
      color: theme.palette.primary.light,
    },
  },
}));

function equal(objA, objB) {
  return JSON.stringify(objA) === JSON.stringify(objB);
}

function FilterMenu(props) {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const { open, onClose, intl } = props;

  const optionDrawerDefaultProps = {
    title: '',
    options: [],
    onSelect: () => {},
    selectedId: null,
    intl,
  };

  // Selectors
  const dispatch = useDispatch();
  const selectedCategory = useSelector(searchSelectors.selectedCategory);
  const selectedLocation = useSelector(searchSelectors.selectedLocation);
  const currency = useSelector(searchSelectors.currency);
  const order = useSelector(searchSelectors.order);
  const gte = useSelector(searchSelectors.gte);
  const lte = useSelector(searchSelectors.lte);
  const options = useSelector(searchSelectors.options);
  const parameters = useSelector(searchSelectors.parameters);

  const stateFilters = useMemo(() => {
    return {
      selectedCategory: selectedCategory || 'all',
      selectedLocation: selectedLocation || 'all',
      order: order || '-upvote_date',
      gte: gte || '',
      lte: lte || '',
      currency: currency || 'KGS',
      options: options || [],
      parameters: parameters || [],
    };
  }, [
    selectedCategory,
    selectedLocation,
    currency,
    order,
    gte,
    lte,
    options,
    parameters,
  ]);

  const isLocationsLoading = useSelector(appSelectors.isLocationsLoading);
  const isCategoriesLoading = useSelector(appSelectors.isCategoriesLoading);

  const [filters, setFilters] = useState(stateFilters);
  const [optionDrawerProps, setOptionDrawerProps] = useState(
    optionDrawerDefaultProps,
  );
  const [drawerNode, setDrawerNode] = useState(null);
  const [categoryParams, setCategoryParams] = useState(null);
  const [categoryParamsLoading, setCategoryParamsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const categories = useSelector(appSelectors.categories);

  const { getCategories } = useCategories();

  useEffect(() => {
    if (categories.length) return;
    getCategories();

    return () => {};
  });

  const history = useHistory();

  const locations = useSelector(appSelectors.locations);

  const { getAllLocations } = useAllLocations();

  useEffect(() => {
    getAllLocations();

    return () => {};
  }, []);

  const isLoading = useMemo(() => {
    return isLocationsLoading || isCategoriesLoading;
  }, [isLocationsLoading, isCategoriesLoading]);

  const list = useMemo(() => {
    const localCategories = JSON.parse(localStorage.getItem('iconCategories'));
    if (categories && categories?.length > 0) return categories;
    return localCategories && localCategories?.length > 0
      ? localCategories
      : null;
  }, [categories]);

  const categoryLine = useMemo(() => {
    if (list && filters.selectedCategory !== 'all') {
      const categoryLine = getObjectLineFromTree(
        list,
        filters.selectedCategory,
      );
      return categoryLine;
    }
    return [];
  }, [list, filters]);

  // selected location's object
  const currentLocation = useMemo(() => {
    if (!locations || filters.selectedLocation === 'all') return null;
    const locationsLine = getObjectLineFromTree(
      locations,
      filters.selectedLocation,
    );
    const loc = locationsLine[locationsLine.length - 1];

    return loc || null;
  }, [locations, filters]);

  // TODO: make data below dynamic
  const orders = [
    {
      id: new Date(),
      title_ru: 'Сначала новые',
      title_ky: 'Жаңылары биринчи',
      title_slug: '-upvote_date',
    },
    {
      id: new Date(),
      title_ru: 'Сначала дешевле',
      title_ky: 'Арзандары биринчи',
      title_slug: 'price',
    },
    {
      id: new Date(),
      title_ru: 'Сначала дороже',
      title_ky: 'Кымбаттары биринчи',
      title_slug: '-price',
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    let inputValue = value;

    if (name.indexOf('price') !== -1) {
      inputValue = inputValue.replace(/\D/g, '');
    }

    setFilters((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const handleCheckValid = () => {
    const priceFrom = filters.gte || 0;
    const priceTo = filters.lte || 0;
    const deliveryFrom = filters.parameters[0]?.from || 0;
    const deliveryTo = filters.parameters[0]?.to || 0;

    if (Number(priceTo) !== 0 && Number(priceFrom) > Number(priceTo)) {
      alert.error('Цена от не может быть больше цены до');
      setIsDisabled(true);

      return;
    }

    if (Number(deliveryTo) !== 0 && Number(deliveryFrom) > Number(deliveryTo)) {
      alert.error('Цена доcтавки от не может быть меньше цены от');
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  };

  const handleReset = () => {
    if (equal(filters, defaultState)) return;

    setFilters(defaultState);
    setCategoryParams(null);

    if (!drawerNode?.firstChild) return;

    drawerNode.firstChild.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = useCallback(() => {
    if (equal(filters, stateFilters)) return;

    if (filters.selectedCategory !== stateFilters.selectedCategory) {
      const categoryLine = getObjectLineFromTree(
        list,
        filters.selectedCategory,
      );
      const category = categoryLine[categoryLine.length - 1];

      dispatch(searchActions.changeLocationAsync(filters.selectedLocation));
      dispatch(searchActions.changeOrderAsync(filters.order));
      dispatch(searchActions.changeGteAsync(filters.gte));
      dispatch(searchActions.changeLteAsync(filters.lte));
      dispatch(searchActions.changeCurrencyAsync(filters.currency));
      dispatch(searchActions.changeOptionsAsync(filters.options));
      dispatch(searchActions.changeParametersAsync(filters.parameters));

      if (filters.selectedCategory === 'all') {
        dispatch(searchActions.changeCategoryAsync('all'));
      }

      const loc = {
        pathname:
          filters.selectedCategory === 'all'
            ? '/'
            : paths.CATEGORY_PAGE(category.title_slug),
        state: {
          fromFilterMenu: true,
        },
      };

      history.push(loc);
      return;
    }

    dispatch(searchActions.changeLocationAsync(filters.selectedLocation));
    dispatch(searchActions.changeOrderAsync(filters.order));
    dispatch(searchActions.changeGteAsync(filters.gte));
    dispatch(searchActions.changeLteAsync(filters.lte));
    dispatch(searchActions.changeCurrencyAsync(filters.currency));
    dispatch(searchActions.changeOptionsAsync(filters.options));
    dispatch(searchActions.changeParametersAsync(filters.parameters));
  }, [dispatch, filters, stateFilters, history, list]);

  const fetchCategoryParams = useCallback(
    async (id) => {
      try {
        setCategoryParamsLoading(true);
        const newCatParams = await dispatch(
          postActions.getCategoryParameters(id, true),
        );

        setCategoryParams(newCatParams);
        setCategoryParamsLoading(false);
      } catch (error) {
        setCategoryParamsLoading(false);
        console.error(error);
      }
    },
    [dispatch],
  );

  const handleCategoryClick = useCallback(() => {
    const newProps = {
      title: intl.formatMessage({ ...messages.chooseCategory }),
      options: list,
      onSelect: (cat) => {
        setFilters({
          ...defaultState,
          selectedCategory: cat.id,
        });
        fetchCategoryParams(cat.id);
      },
      selectedId: filters.selectedCategory,
      // ...optionDrawerProps
    };

    setOptionDrawerProps((prevState) => ({
      ...prevState,
      ...newProps,
    }));
  }, [list, filters, fetchCategoryParams, intl]);

  const handleSortClick = useCallback(() => {
    const selectedOrder = orders.find(
      (ord) => ord.title_slug === filters.order,
    );
    const newProps = {
      title: intl.formatMessage({ ...messages.order }),
      options: orders,
      onSelect: (ord) => {
        handleChange({
          target: { name: 'order', value: ord.title_slug },
        });
      },
      selectedId: selectedOrder ? selectedOrder.id : '-upvote_date',
    };

    setOptionDrawerProps((prevState) => ({
      ...prevState,
      ...newProps,
    }));
  }, [orders, filters, intl]);

  const handleLocationClick = useCallback(() => {
    const newProps = {
      title: intl.locale === 'ru' ? 'Местоположение' : 'Жайгашкан жери',
      options: locations.length > 0 ? locations[0].children : [],
      onSelect: (loc) => {
        handleChange({
          target: { name: 'selectedLocation', value: loc.id },
        });
      },
      selectedId:
        filters.selectedLocation === 'all' ? 'all' : filters.selectedLocation,
    };

    setOptionDrawerProps((prevState) => ({
      ...prevState,
      ...newProps,
    }));
  }, [locations, filters, intl]);

  // accepts parameter
  const handleCategoryParameterChange = (param) => {
    if (!param) return;

    const { parameters } = filters;
    const paramIdx = parameters.findIndex((p) => param.id === p.id);
    if (paramIdx !== -1) {
      const changeState = ({ parameters, ...prevState }) => {
        const notEmpty = param.from || param.to;
        const newParameters = notEmpty
          ? [
              ...parameters.slice(0, paramIdx),
              param,
              ...parameters.slice(paramIdx + 1),
            ]
          : [
              ...parameters.slice(0, paramIdx),
              ...parameters.slice(paramIdx + 1),
            ];
        return {
          ...prevState,
          parameters: newParameters,
        };
      };

      setFilters(changeState);
    } else {
      setFilters((prevState) => ({
        ...prevState,
        parameters: [...parameters, param],
      }));
    }
  };

  const handleCategoryOptionChange = (option) => {
    const { options } = filters;
    const optionIdx = options.findIndex((opt) => opt.id === option.id);

    setFilters(({ options, ...prevState }) => ({
      ...prevState,
      options:
        optionIdx !== -1
          ? [...options.slice(0, optionIdx), ...options.slice(optionIdx + 1)]
          : [...options, option],
    }));
  };

  const handleCategoryFilterChange = (filter, payload) => {
    if (filter.type === 'enter') {
      handleCategoryParameterChange(payload);
    } else {
      handleCategoryOptionChange(payload);
    }
  };

  const syncWithState = () => {
    if (JSON.stringify(filters) !== JSON.stringify(stateFilters)) {
      setFilters(stateFilters);
    }
  };

  // effects
  useEffect(() => {
    setFilters(stateFilters);
  }, [stateFilters]);

  useEffect(() => {
    if (selectedCategory === 'all') {
      setCategoryParams([]);
      return;
    }
    fetchCategoryParams(selectedCategory);
  }, [fetchCategoryParams, selectedCategory]);

  function convertOrder(order) {
    switch (order) {
      case '-upvote_date':
        return intl.formatMessage({ ...messages.new });
      case 'price':
        return intl.formatMessage({ ...messages.cheap });
      case '-price':
        return intl.formatMessage({ ...messages.expensive });
      default:
        return null;
    }
  }

  const currentCategory = categoryLine.reduce(
    (acc, cat) => `${acc}${acc && '/'}${cat[`title_${intl.locale}`]}`,
    '',
  );

  const CustomSelect = withStyles((theme) => ({
    root: {
      '& input': {
        fontSize: 18,
        color: theme.palette.primary.blue,
      },
      '& input::placeholder': {
        color: theme.palette.primary.blue,
        opacity: 1,
      },
    },
  }))(({ classes, onClick, label, ...props }) => (
    <TextField
      className={classes.root}
      defaultValue="1"
      onClick={onClick}
      select
      SelectProps={{
        IconComponent: KeyboardArrowDown,
        open: false,
        style: {
          fontSize: 18,
          color: theme.palette.primary.blue,
        },
      }}
      {...props}
    >
      <MenuItem value="1">{label}</MenuItem>
    </TextField>
  ));

  function pasteMesssage(ru, ky) {
    return intl.locale === 'ru' ? ru : ky;
  }

  return (
    <SwipeableDrawer
      variant="persistent"
      anchor="right"
      open={open}
      className={classes.root}
      ref={(node) => setDrawerNode(node)}
      onOpen={() => {}}
      onClose={() => {}}
    >
      <Box>
        <Box
          padding="10px"
          position="relative"
          borderBottom="1px solid #C4C4C4"
        >
          <Typography
            variant="h3"
            component="h3"
            align="center"
          >
            {intl.formatMessage({ ...messages.filters })}
          </Typography>
          <IconButton
            onClick={() => {
              syncWithState();
              onClose();
            }}
            className={classes.menuClose}
          >
            <Close fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.container}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Typography
              component="h4"
              variant="subtitle1"
            >
              {pasteMesssage('Категория', 'Категория')}
            </Typography>
            <div className={classes.inputBox}>
              <CustomSelect
                label={
                  currentCategory ||
                  intl.formatMessage({ ...messages.chooseCategory })
                }
                onClick={handleCategoryClick}
              />
            </div>

            <Typography
              component="h4"
              variant="subtitle1"
            >
              {pasteMesssage('Местоположение', 'Жайгашкан жери')}
            </Typography>
            <div className={classes.inputBox}>
              <CustomSelect
                label={
                  currentLocation
                    ? currentLocation[`title_${intl.locale}`]
                    : 'Кыргызстан'
                }
                onClick={handleLocationClick}
              />
            </div>

            <Typography
              component="h4"
              variant="subtitle1"
            >
              {intl.formatMessage({ ...messages.order })}
            </Typography>
            <div className={classes.inputBox}>
              <CustomSelect
                label={convertOrder(filters.order)}
                onClick={handleSortClick}
              />
            </div>

            <Typography
              component="h4"
              variant="subtitle1"
            >
              {intl.formatMessage({ ...messages.price })}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              pt={`${theme.spacing(1)}px`}
              mb={`${theme.spacing(2)}px`}
            >
              <Box width="47%">
                <TextField
                  value={filters.gte}
                  onChange={handleChange}
                  name="gte"
                  placeholder={intl.formatMessage({
                    ...messages.priceFrom,
                  })}
                  className={classes.textField}
                  onBlur={handleCheckValid}
                />
              </Box>

              <Box width="47%">
                <TextField
                  value={filters.lte}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ ...messages.to })}
                  name="lte"
                  className={classes.textField}
                  onBlur={handleCheckValid}
                />
              </Box>
            </Box>

            <Box
              pl={`${theme.spacing(1)}px`}
              pr={`${theme.spacing(1)}px`}
              mb={`${theme.spacing(2)}px`}
              border="border: 1px solid #E0E0E0"
            >
              <ToggleButtonGroup
                exclusive
                value={filters.currency}
                onChange={(_, value) =>
                  handleChange({
                    target: {
                      name: 'currency',
                      value: value || filters.currency,
                    },
                  })
                }
                style={{
                  width: '100%',
                  backgroundColor: theme.palette.primary.light,
                  padding: `${theme.spacing(0.5)}px`,
                  justifyContent: 'space-between',
                }}
              >
                <ToggleButton
                  value="KGS"
                  className={classes.currencyItem}
                  style={{
                    borderRadius: '5px',
                    maxHeight: `${40}px`,
                  }}
                >
                  COM
                </ToggleButton>
                <ToggleButton
                  value="USD"
                  className={classes.currencyItem}
                  style={{
                    borderRadius: '5px',
                    maxHeight: `${40}px`,
                  }}
                >
                  USD
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {selectedCategory !== 'all' && categoryParamsLoading ? (
              <Skeleton />
            ) : (
              <CategoryFilters
                filters={categoryParams}
                parameters={filters.parameters}
                options={filters.options}
                onChange={handleCategoryFilterChange}
                intl={intl}
                onBlur={handleCheckValid}
              />
            )}
          </>
        )}

        <Grid
          container
          spacing={1}
          className={classes.btnContainer}
        >
          <Grid
            item
            xs={6}
          >
            <Button
              // size="large"
              variant="outlined"
              className={clsx(classes.btn, classes.resetBtn)}
              onClick={handleReset}
            >
              {intl.formatMessage({ ...messages.reset })}
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
          >
            <Button
              className={clsx(classes.btn, classes.submitBtn)}
              onClick={() => {
                handleSubmit();
                onClose();
              }}
              disabled={isDisabled}
            >
              {intl.formatMessage({ ...messages.accept })}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Options Drawer */}
      <OptionDrawer
        open={optionDrawerProps.options.length > 0}
        onClose={() => setOptionDrawerProps(optionDrawerDefaultProps)}
        {...optionDrawerProps}
      />
    </SwipeableDrawer>
  );
}

FilterMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  intl: intlShape,
};

export default compose(memo, injectIntl)(FilterMenu);
