import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import WithSearchBar from 'layouts/WithSearchBar';
import { useDispatch } from 'react-redux';
import { searchActions } from 'states/Search';
import HomePage from './HomePage';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: '0',
    '@media (max-width: 1125px)': {
      paddingLeft: '26px',
      paddingRight: '26px',
    },
    '@media (max-width: 720px)': {
      padding: '0',
    },
  },
}));

export default function HomePageWithLayout() {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      dispatch(searchActions.changeCategoryAsync('all'));
    }
  }, [dispatch, location]);

  const isRender = useMemo(() => {
    return (
      location.pathname === '/' || location.pathname.includes('/link/mail_auth')
    );
  }, [location.pathname]);

  return (
    isRender && (
      <WithSearchBar className={classes.container}>
        <HomePage />
      </WithSearchBar>
    )
  );
}
