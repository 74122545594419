import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import {
  makeStyles,
  Typography,
  Box,
  TextField,
  useTheme,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputBox: {
    // paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textField: {
    '& input': {
      fontSize: 18,
      color: theme.palette.primary.blue,
    },
    '& input::placeholder': {
      color: theme.palette.primary.blue,
      opacity: 1,
    },
  },
  checkbox: {
    color: theme.palette.primary.blue,

    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.blue,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  },
  checkboxLabel: {
    fontSize: 16,
    color: theme.palette.primary.contrastText,
  },
}));

function CategoryFilters(props) {
  const { filters, options, parameters, onChange, onBlur, intl } = props;

  const classes = useStyles();

  if (!filters) return <></>;

  return (
    <>
      {filters.map((filter) => {
        const value = parameters.find((val) => val.id === filter.id);

        const filterBodyProps = {
          filter,
          onChange,
          onBlur,
          intl,
        };

        let filterBody;

        if (filter.type === 'enter') {
          filterBody = (
            <FromTo
              value={value}
              {...filterBodyProps}
            />
          );
        } else {
          filterBody = (
            <Select
              value={options}
              {...filterBodyProps}
            />
          );
        }

        return (
          <React.Fragment key={filter.id}>
            <Typography
              component="h4"
              variant="subtitle1"
            >
              {filter[`title_${intl.locale}`]}
            </Typography>
            <div className={classes.inputBox}>{filterBody}</div>
          </React.Fragment>
        );
      })}
    </>
  );
}

CategoryFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  parameters: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  intl: intlShape,
};

export function FromTo(props) {
  const { filter, value, onChange, onBlur } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pl={`${theme.spacing(1)}px`}
      pt={`${theme.spacing(1)}px`}
      mb={`${theme.spacing(2)}px`}
    >
      <Box width="47%">
        <TextField
          value={value?.from || ''}
          onChange={(e) =>
            onChange(filter, {
              id: filter.id,
              to: value?.to || '',
              from: e.target.value,
              type: 'enter',
            })
          }
          name="gte"
          placeholder="от"
          className={classes.textField}
          onBlur={onBlur}
        />
      </Box>
      <Box width="47%">
        <TextField
          value={value?.to || ''}
          onChange={(e) =>
            onChange(filter, {
              id: filter.id,
              from: value?.from || '',
              to: e.target.value,
              type: 'enter',
            })
          }
          placeholder="до"
          name="lte"
          className={classes.textField}
          onBlur={onBlur}
        />
      </Box>
    </Box>
  );
}

export function Select(props) {
  const { filter, value: options, onChange, intl } = props;
  const classes = useStyles();

  function isOptionChecked(opt) {
    return options.findIndex((option) => option.id === opt.id) !== -1;
  }

  return (
    <>
      <FormControl>
        <FormGroup>
          {filter.options.map((opt, idx) =>
            idx % 2 === 0 ? (
              <FormControlLabel
                key={opt.id}
                control={
                  <Checkbox
                    checked={isOptionChecked(opt)}
                    onChange={() => onChange(filter, opt)}
                    className={classes.checkbox}
                  />
                }
                label={
                  <span className={classes.checkboxLabel}>
                    {opt[`title_${intl.locale}`]}
                  </span>
                }
              />
            ) : null,
          )}
        </FormGroup>
      </FormControl>
      <FormControl>
        <FormGroup>
          {filter.options.map((opt, idx) =>
            idx % 2 !== 0 ? (
              <FormControlLabel
                key={opt.id}
                control={
                  <Checkbox
                    checked={isOptionChecked(opt)}
                    onChange={() => onChange(filter, opt)}
                    className={classes.checkbox}
                  />
                }
                label={
                  <span className={classes.checkboxLabel}>
                    {opt[`title_${intl.locale}`]}
                  </span>
                }
              />
            ) : null,
          )}
        </FormGroup>
      </FormControl>
    </>
  );
}

const filterPropTypes = {
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

FromTo.propTypes = {
  ...filterPropTypes,
  value: PropTypes.oneOf([null, PropTypes.object]),
};

Select.propTypes = {
  ...filterPropTypes,
  value: PropTypes.any,
  intl: intlShape,
};

export default CategoryFilters;
