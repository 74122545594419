import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';

import { Grid, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
} from '@material-ui/icons';
import { intlShape } from 'react-intl';
import clsx from 'clsx';

function Desktop({
  intl,
  categories,
  classes,
  categoryLine,
  onClick,
  messages,
}) {
  const theme = useTheme();
  const categoriesToDisplay = 12;
  const { category, subcategory, tripcategory } = useParams();

  const [showMore, setShowMore] = useState(false);

  const colSize = 3;

  const childrenLength = categories?.length;

  const subCategories =
    categories &&
    categories
      ?.slice(0, showMore ? childrenLength : categoriesToDisplay)
      .map((child) => (
        <Grid
          item
          md={colSize}
          sm={colSize}
          key={child?.id}
        >
          <NavLink
            className={classes.link}
            key={child?.id}
            to={
              subcategory
                ? `/category/${category}/${subcategory}/${child.title_slug}`
                : `/category/${category}/${child.title_slug}`
            }
          >
            <Typography
              className={classes.subCategory}
              onClick={() => onClick(child)}
            >
              {child[`title_${intl.locale}`]}
            </Typography>
          </NavLink>
        </Grid>
      ));

  const marginBottom =
    categories && categories.length ? theme.spacing(7) : theme.spacing(3.5);

  const urlTo = (url) => {
    if (category && subcategory === url) {
      return `/category/${category}/${url}`;
    } else if (category === url) {
      return `/category/${category}`;
    }
    return `/category/${url}`;
  };

  return (
    <Box className={classes.selectedCategoryRoot}>
      <Box
        className={classes.currentCategory}
        marginBottom={`${marginBottom}px`}
      >
        <div
          style={{
            marginBottom: 25,
          }}
        >
          <Typography
            component={NavLink}
            to="/"
            className={classes.subCategoriesTitle}
          >
            {intl.formatMessage({ ...messages.mainPage })}
          </Typography>
          {categoryLine?.map((category, idx) => {
            if (idx === 0) {
              return (
                <Typography
                  component={NavLink}
                  to={urlTo(category?.title_slug)}
                  key={category?.id}
                  onClick={() => onClick(category)}
                  className={clsx(
                    classes.subCategoriesTitle,
                    classes.withDecoration,
                  )}
                >
                  {category[`title_${intl.locale}`]}
                </Typography>
              );
            }
            return (
              <Typography
                component={NavLink}
                to={urlTo(category?.title_slug)}
                key={category?.id}
                onClick={() => onClick(category)}
                className={clsx(
                  classes.subCategoriesTitle,
                  classes.withDecoration,
                )}
              >
                {category[`title_${intl.locale}`]}
              </Typography>
            );
          })}
        </div>
        <Grid
          container
          spacing={2}
          className={classes.subCategoriesContainer}
        >
          {subCategories}
          {childrenLength > 12 && (
            <Grid
              container
              style={{
                marginTop: theme.spacing(3),
              }}
            >
              <Button
                onClick={() => setShowMore((prevState) => !prevState)}
                className={classes.showMoreCategories}
                endIcon={
                  showMore ? (
                    <KeyboardArrowUpSharp />
                  ) : (
                    <KeyboardArrowDownSharp />
                  )
                }
              >
                {showMore
                  ? intl.formatMessage({ ...messages.hide })
                  : intl.formatMessage({ ...messages.allCategories })}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

Desktop.propTypes = {
  intl: intlShape,
  onClick: PropTypes.func,
  categoryLine: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  messages: PropTypes.object,
};

export default Desktop;
