import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#F4F4F4',
    paddingTop: '30px',
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  small: {
    maxWidth: '960px',
    width: '960px',
    height: '85px',
    borderTop: '1px solid #DBDBDB',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    paddingLeft: '30px',
    paddingRight: '30px',
    '@media (min-width: 1920px)': {
      padding: '0',
    },
    '@media (max-width: 1125px)': {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 10,
    },
  },
  logo: {
    '& img': {
      width: '150px',
    },
  },
  text: {
    minWidth: '192px',
    maxWidth: '230px',
    height: '16px',
    fontSize: '14px',
    lineHeight: '16.4px',
    color: '#C2C4CB',
    fontWeight: 'normal',
    paddingTop: '10px',
    marginRight: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
  },
  socials: {
    display: 'flex',
    paddingTop: '10px',
    justifyContent: 'space-between',
    width: '300px',
    '& a': {
      width: '25px',
    },
    '@media (max-width: 920px)': {
      marginRight: '30px',
    },
  },
  social_block: {
    width: '25px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid #0A142F',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      border: '0',
      background: '#FAC917',
      '& path': {
        fill: '#fff',
      },
    },
  },
  flexes: {
    display: 'flex',
  },
  big: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '960px',
    width: '960px',
    '@media (min-width: 1920px)': {
      padding: '0',
    },
    '@media (max-width: 1125px)': {
      width: '100%',
      maxWidth: '100%',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  name: {
    color: '#0051BA',
    fontSize: '15px',
    fontWeight: '700',
  },
  bigTitle: {
    maxWidth: '445px',
    paddingRight: '10px',
    minHeight: '125px',
    paddingTop: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    opacity: '0.8',
    lineHeight: '25px',
  },
  navigations: {
    width: '234px',
    height: '154px',
    '& ul': {
      padding: '0',
    },
    '& li': {
      listStyle: 'none',
    },
    '& a': {
      opacity: '0.8',
      color: '#1B1B1B',
      fontSize: '15px',
      textDecoration: 'none',
      fontWeight: '400',
      lineHeight: '30px',
      transition: 'all 0.2s',
      '&:hover': {
        color: '#000',
        fontSize: '15.5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'min-content',
    },
  },
  downloadApps: {
    minWidth: '155px',
    maxWidth: '175px',
    height: '154px',
    '& img': {
      transition: 'all 0.3s',
      width: '122px',
      marginTop: '12px',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  },
}));
