import React from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';

const Tips = (props) => {
  const { debounce, tips, onSubmit, onChangeSearch } = props;

  const classes = useStyles();

  return (
    <Box className={classes.modal}>
      <Box
        className={classes.tip}
        onClick={() => {
          onSubmit();
        }}
      >
        {debounce}
      </Box>

      {tips?.map((item, i) => (
        <Box
          className={classes.tip}
          onClick={() => {
            onSubmit(item?.keyword);
            onChangeSearch(item?.keyword);
          }}
          key={i}
        >
          {item?.keyword.substring(0, 19)}
          {item?.keyword.length > 19 && '...'}
        </Box>
      ))}
    </Box>
  );
};

export default Tips;
