import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '1px solid rgb(242, 242, 242)',

    '& *::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: '5px',
    },
    '& *::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&  *::-webkit-scrollbar': {
      width: '5px',
    },
    '& .MuiButton-root': {
      fontSize: '14px',
    },
  },

  searching: {
    position: 'sticky',
    top: 20,
  },
  searchCollapse: {
    position: 'relative',
    zIndex: -1,
    // top: '100%',
    // left: 0,
    // right: 0
  },

  closeButton: {
    position: 'absolute',
    width: 35,
    height: '35px !important',
    right: 75,
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50% !important',
  },

  container: {
    maxWidth: '960px',
    margin: '0 auto',
  },
  searchWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: theme.breakpoints.values.md,
    margin: '0 auto',
  },
  textfield: {
    width: '100%',
    background: '#fff',
    boxShadow: '2px 0px 5px 2px rgba(50, 50, 71, .06)',
    borderRadius: 5,
    height: 50,
    margin: theme.spacing(2, 0),

    '& .MuiOutlinedInput-root:focus': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      height: 'inherit',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      paddingRight: 32,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  postBtn: {
    color: theme.palette.main,
    minWidth: 160,
    height: 50,
    marginLeft: 20,
    fontWeight: 500,
    textTransform: 'none',
  },
  searchPropInput: {
    '&$searchFieldFocused $notchedOutline': {
      border: 'none',
      borderWidth: 0,
    },
    '&:hover $notchedOutline': {
      border: 'none',
    },
  },
  notchedOutline: {
    border: 'none',
    borderWidth: 0,
  },
  searchFieldFocused: {},
  searchAdornment: {
    height: 50,

    '& button': {
      minWidth: '35px',
      height: 'inherit',
      borderRadius: 5,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: 'none',
    },
  },
  menuButton: {
    flexGrow: 1,
    height: 50,
    borderRadius: 5,
    margin: '0 20px 0 0',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.07)',
    fontSize: '17px !important',
    textTransform: 'none',

    // '&:hover': {
    //   '& path': {
    //     stroke: '#fff !important'
    //   }
    // },

    '& .MuiButton-label': {
      justifyContent: 'space-around',
    },
  },
  activeFilterButton: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },

  mainCategory: {
    textTransform: 'none',
    fontSize: '15px',
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderRadius: '5px',
  },

  mainCategoryIcon: {
    marginRight: '5px',
  },

  childCategoryList: {
    '&::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
  },

  showMoreBtn: {
    justifyContent: 'flex-start',
    padding: 0,
    borderRadius: '5px',
    fontSize: '13px',
    textTransform: 'none',
    color: theme.palette.primary.blue,

    '&:hover': {
      backgroundColor: 'unset',
    },
  },

  // filter
  paper: {
    padding: 20,
    boxShadow:
      '0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)',
    fontSize: '19px',

    '& label': {
      fontWeight: 500,
      fontSize: 14,
      color: '#4B4B4B',
    },
    '& .MuiSelect-root .MuiListItemIcon-root, .MuiSelect-root .MuiSvgIcon-root':
      {
        display: 'none',
      },
    '& .MuiSelect-root .MuiListItemText-root': {
      marginBottom: 0,
      marginTop: 0,

      '& .MuiListItemText-primary': {
        lineHeight: 'inherit',
      },
    },
    '& .MuiOutlinedInput-root': {
      width: 250,
    },
    '& .MuiFilledInput-root': {
      height: 30,
      borderRadius: 5,
      padding: 0,

      '& .MuiFilledInput-inputMarginDense': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    '& .MuiFilledInput-root::before, .MuiFilledInput-root::after': {
      display: 'none',
    },
    '& .MuiGrid-grid-md-4': {
      paddingTop: 22,
      paddingBottom: 22,
    },
  },
  textPrimary: {
    marginRight: 10,
  },
  productCount: {
    color: '#8E8E93',
    fontSize: 12,
    fontWeight: 500,
  },
  currency: {
    display: 'block',
    marginTop: 8,
    textAlign: 'center',

    '& button': {
      width: 115,
      height: 37,
      margin: '0 1px',
      borderRadius: '5px !important',
    },
  },
  price: {
    width: 115,

    '& .MuiFilledInput-root': {
      height: 37,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: theme.spacing(3.3),

    '& .MuiButton-contained.Mui-disabled': {
      background: '#E1E1E1',
      color: `${theme.palette.primary.contrastText} !important`,
    },
    '& button': {
      height: 37,
      borderRadius: 10,
      padding: '0 27px',
      textTransform: 'inherit',

      '&:first-child': {
        borderColor: theme.palette.primary.contrastText,
        color: `${theme.palette.primary.contrastText} !important`,
      },
    },
  },
  navigateNextIcon: {
    fill: '#B5B5B5',
  },
  orderSelect: {
    margin: '0 auto',
    '& fieldset': {
      border: '1px solid #A7A7A7 !important',
    },

    '&:hover': {
      '& svg': {
        fill: theme.palette.primary.main,
      },

      '& fieldset': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
    },
  },
  marginLeft: {
    marginLeft: '1.8em',
  },
  categoryCollapse: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    zIndex: 1,

    '& .MuiPaper-root': {
      // height: '100vh'
    },
  },
  categoryLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  listItem: {
    padding: 0,
  },
}));
