import React, { useState, useCallback, useEffect, memo } from 'react';
import { searchActions, searchSelectors } from 'states/Search';
import { useSelector, useDispatch } from 'react-redux';
import FilterComponent from 'components/Filter';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { postActions } from 'states/Post';
import { appSelectors } from 'providers/AppProvider/data';
import { useAllLocations } from 'hooks/useAllLocations';
import { useAlert } from 'react-alert';

function Filter({ intl, ...rest }) {
  // Selectors
  const alert = useAlert();
  const dispatch = useDispatch();
  const selectedCategory = useSelector(searchSelectors.selectedCategory);
  const selectedLocation = useSelector(searchSelectors.selectedLocation);
  const currency = useSelector(searchSelectors.currency);
  const order = useSelector(searchSelectors.order);
  const gte = useSelector(searchSelectors.gte);
  const lte = useSelector(searchSelectors.lte);
  const options = useSelector(searchSelectors.options);
  const parameters = useSelector(searchSelectors.parameters);

  const locationsObj = useSelector(appSelectors.locationsObj);
  const locations = useSelector(appSelectors.locations);

  const { getAllLocations } = useAllLocations();

  useEffect(() => {
    getAllLocations();
  }, []);

  const [filters, setFilters] = useState({
    selectedLocation: selectedLocation || 'all',
    order: order || '-upvote_date',
    gte: gte || '',
    lte: lte || '',
    currency: currency || '',
    options,
    parameters,
  });
  const [categoryParams, setCategoryParams] = useState(null);
  const [categoryParamsLoading, setCategoryParamsLoading] = useState(false);

  const isGteError = Number(filters.gte) > Number(filters.lte);
  const isLteError = Number(filters.lte) < Number(filters.gte);

  const changeState = (setState, newState) => {
    setState(newState);
  };

  const fetchCategoryParams = useCallback(
    async (id) => {
      try {
        changeState(setCategoryParamsLoading, true);

        const newCatParams = await dispatch(
          postActions.getCategoryParameters(id, true),
        );
        changeState(setCategoryParams, newCatParams);
        changeState(setCategoryParamsLoading, false);
      } catch (error) {
        changeState(setCategoryParamsLoading, false);
        console.error(error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setFilters({
      selectedLocation: selectedLocation || 'all',
      order: order || '-upvote_date',
      gte: gte || '',
      lte: lte || '',
      currency: currency || '',
      options,
      parameters,
    });
  }, [selectedLocation, order, gte, lte, currency, options, parameters]);

  useEffect(() => {
    fetchCategoryParams(selectedCategory);
  }, [selectedCategory, fetchCategoryParams]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    let inputValue = value;

    if (name.indexOf('price') !== -1) {
      inputValue = inputValue.replace(/\D/g, '');
    }

    setFilters((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  }, []);

  const handleCheckValid = (e) => {
    const { name, value } = e.target;

    if (filters.lte.length === 0 || filters.gte.length === 0) return;
    if (name === 'gte' && Number(value) > Number(filters.lte)) {
      // FIX_ME: Нужен перевод
      alert.error('Цена от не может быть больше цены до');
    }

    if (name === 'lte' && Number(value) < Number(filters.gte)) {
      alert.error('Цена до не может быть меньше цены от');
    }
  };

  const handleReset = useCallback(() => {
    setFilters({
      selectedCategory: 'all',
      selectedLocation: 'all',
      order: '-upvote_date',
      gte: '',
      lte: '',
      currency: 'KGS',
      options: [],
      parameters: [],
    });
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(searchActions.changeLocationAsync(filters.selectedLocation));
    dispatch(searchActions.changeOrderAsync(filters.order));
    dispatch(searchActions.changeGteAsync(filters.gte));
    dispatch(searchActions.changeLteAsync(filters.lte));
    dispatch(searchActions.changeCurrencyAsync(filters.currency));
    dispatch(searchActions.changeOptionsAsync(filters.options));
    dispatch(searchActions.changeParametersAsync(filters.parameters));
  }, [filters, dispatch]);

  return (
    <FilterComponent
      values={filters}
      categoryParams={categoryParams}
      categoryParamsLoading={categoryParamsLoading}
      locations={locations}
      locationsObj={locationsObj}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onReset={handleReset}
      onBlur={handleCheckValid}
      intl={intl}
      isDisabled={isGteError || isLteError}
      {...rest}
    />
  );
}

Filter.propTypes = {
  intl: intlShape,
};

export default compose(memo, injectIntl)(Filter);
