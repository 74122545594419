import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { paths } from 'common/constants';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { productActions } from 'states/Products';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  itemsList: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1.2),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 100,
    height: 33,
    lineHeight: '1em',
    color: theme.palette.primary.light,
    marginRight: theme.spacing(1),
    // background: `url(${ItemIcon}) no-repeat`,
    backgroundColor: 'grey',
    backgroundSize: 'cover',
    textAlign: 'center',
    borderRadius: '6px',
    textDecoration: 'none',
  },
}));

function Mobile(props) {
  const dispatch = useDispatch();
  const { categories, intl } = props;
  const classes = useStyles();
  return (
    <div>
      <Typography
        className={classes.title}
        variant="subtitle1"
        component="h3"
      >
        {intl.locale === 'ru' ? 'Главные категории' : 'Негизги категориялар'}
      </Typography>
      <div className={classes.itemsList}>
        {categories.map((cat) => {
          let catName = cat[`title_${intl.locale}`];
          if (catName === 'Благотворительность') {
            catName = (
              <>
                Благотвори-
                <br />
                тельность
              </>
            );
          }
          return (
            <NavLink
              key={cat?.id}
              to={paths.CATEGORY_PAGE(cat.title_slug)}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)), url(${cat.image})`,
              }}
              onClick={() => dispatch(productActions.clearProductListAsync())}
              className={classes.item}
            >
              {catName}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

Mobile.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  intl: intlShape,
};

export default Mobile;
