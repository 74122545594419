import { createActions } from 'redux-actions';

import { createRequestAction, getResponseError } from 'common/helpers';
import { ENDPOINTS, ACTION_TYPES } from './constants';

const actionCreators = createActions({
  CLEAR_PRODUCT_LIST_SHORT: null,
  CLEAR_PRODUCT: null,
  SET_PREV_PARAMS: null,
  ...createRequestAction(ACTION_TYPES.GET_PRODUCT_LIST),
  ...createRequestAction(ACTION_TYPES.GET_PRODUCT_LIST_SHORT),
  ...createRequestAction(ACTION_TYPES.GET_PRODUCT_INFO),
  ...createRequestAction(ACTION_TYPES.GET_FAVORITES),
  ...createRequestAction(ACTION_TYPES.GET_PERSONAL_PRODUCTS),
  ...createRequestAction(ACTION_TYPES.GET_ADS),
  ...createRequestAction(ACTION_TYPES.GET_CLAIM_CATEGORIES),
  ...createRequestAction(ACTION_TYPES.SEND_CLAIM_TO_PRODUCT),
  ...createRequestAction(ACTION_TYPES.OPEN_MODAL),
});

export function getProductListShort(
  params,
  notDispatch = false,
  isUserBusiness = false,
  isUser = null,
) {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getProductListShortRequest());
    }
    try {
      const isBusiness = JSON.parse(
        localStorage.getItem('_ts:isBusinessProducts'),
      );
      const isCategory = Boolean(params.category);
      if (params.search) {
        params = { ...params, category: undefined };
      }

      const isBusinessProducts = () => {
        if (isUser !== null) {
          return isUser ? 'True' : 'False';
        } else if (isBusiness === true) {
          return 'True';
        } else {
          return 'False';
        }
      };

      let URL = `${
        ENDPOINTS.PRODUCTS_LIST
      }?is_business=${isBusinessProducts()}`;

      const { data } = await api.get(URL, { params });
      if (!notDispatch) {
        dispatch(
          actionCreators.getProductListShortSuccess({
            ...data,
            meta: { isBusiness, isCategory },
          }),
        );
      }
      return data;
    } catch (e) {
      const error = getResponseError(e);
      if (!notDispatch) {
        dispatch(actionCreators.getProductListShortFail(error));
      }
      return Promise.reject(error);
    }
  };
}

export function getProductInfo(id, notDispatch = false) {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getProductInfoRequest());
    }
    try {
      const { data } = await api.get(
        `${
          process.env.REACT_APP_BASE_URL === 'https://api.dev.tezsat.kg'
            ? ENDPOINTS.DEV_LIST
            : ENDPOINTS.PRODUCTS
        }${id}`,
      );

      if (!notDispatch) {
        dispatch(actionCreators.getProductInfoSuccess(data));
      }
      return data;
    } catch (e) {
      const error = getResponseError(e);
      if (!notDispatch) {
        dispatch(actionCreators.getProductInfoFail(error));
      }
      return Promise.reject(error);
    }
  };
}

export const clearProductAsync = () => async (dispatch) => {
  dispatch(actionCreators.clearProduct());
};

export const clearProductListAsync = () => async (dispatch) => {
  dispatch(actionCreators.clearProductListShort());
};

export function getFavorites(params, notDispatch = false) {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getFavoritesRequest());
    }
    try {
      const { data } = await api.get(ENDPOINTS.FAVORITES, { params });

      if (!notDispatch) {
        dispatch(actionCreators.getFavoritesSuccess(data));
      }
      return data;
    } catch (e) {
      const error = getResponseError(e);
      if (!notDispatch) {
        dispatch(actionCreators.getFavoritesFail(error));
      }
      return Promise.reject(error);
    }
  };
}

export function getPersonalProducts(params, notDispatch = false) {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getPersonalProductsRequest());
    }
    try {
      const { data } = await api.get(ENDPOINTS.PERSONAL, { params });

      if (!notDispatch) {
        dispatch(actionCreators.getPersonalProductsSuccess(data));
      }
      return data;
    } catch (e) {
      const error = getResponseError(e);
      if (!notDispatch) {
        dispatch(actionCreators.getPersonalProductsFail(error));
      }
      return Promise.reject(error);
    }
  };
}

export function call(id) {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(`${ENDPOINTS.PRODUCTS}${id}/call/`);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export function upvote(id) {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(`${ENDPOINTS.PERSONAL}${id}/upvote/`);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export function getAds() {
  return async (dispatch, getState, { api }) => {
    dispatch(actionCreators.getAdsRequest());
    try {
      const { data } = await api.get(ENDPOINTS.ADS);
      dispatch(actionCreators.getAdsSuccess(data));
      return data;
    } catch (e) {
      return e;
    }
  };
}

export function countClick(id) {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(`${ENDPOINTS.COUNT_CLICK}${id}/`);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export const fetchIsOpenModal = () => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.get(ENDPOINTS.IS_OPEN_MODAL);

      dispatch(actionCreators.openModalSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.openModalFail(e));
      return Promise.reject(e);
    }
  };
};

export const getClaimCategories = (params, notDispatch = false) => {
  return async (dispatch, getState, { api }) => {
    if (!notDispatch) {
      dispatch(actionCreators.getClaimCategoriesRequest());
    }
    try {
      const { data } = await api.get(ENDPOINTS.PRODUCT_CLAIMS, { params });

      if (!notDispatch) {
        dispatch(actionCreators.getClaimCategoriesSuccess(data));
      }

      return data;
    } catch (e) {
      if (!notDispatch) {
        dispatch(actionCreators.getClaimCategoriesFail(e));
      }
    }
  };
};

export const sendClaim = (productId, claim) => {
  return async (dispatch, getState, { api }) => {
    try {
      const { data } = await api.post(
        ENDPOINTS.PRODUCT_CLAIM(productId),
        claim,
      );

      dispatch(actionCreators.sendClaimToProductSuccess(data));

      return data;
    } catch (e) {
      dispatch(actionCreators.sendClaimToProductFail(e));
    }
  };
};

export const actions = {
  ...actionCreators,
  getProductListShort,
  getProductInfo,
  getFavorites,
  getPersonalProducts,
  call,
  upvote,
  clearProductAsync,
  clearProductListAsync,
  getAds,
  countClick,
  fetchIsOpenModal,
  getClaimCategories,
  sendClaim,
};
