import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';

import { boolReducer } from 'common/helpers';
import { getStateValue } from 'states/helpers';
import { actions } from './actions';

export const initialState = {
  productListShort: [],
  hasMore: true,
  productInfo: null,
  claimCategories: [],
  isBusiness: false,
  isCategory: false,
  error: null,
  notFound: false,
  prevParams: {},
};

const isListLoading = boolReducer(
  [combineActions(actions.getProductListShortRequest)],
  [
    combineActions(
      actions.getProductListShortSuccess,
      actions.getProductListShortFail,
    ),
  ],
);

const isProductLoading = boolReducer(
  [combineActions(actions.getProductInfoRequest)],
  [combineActions(actions.getProductInfoSuccess, actions.getProductInfoFail)],
);

const isClaimCategoriesLoading = boolReducer(
  [combineActions(actions.getClaimCategoriesRequest)],
  [
    combineActions(
      actions.getClaimCategoriesSuccess,
      actions.getClaimCategoriesFail,
    ),
  ],
);

const data = handleActions(
  {
    [actions.getProductListShortSuccess]: (state, { payload }) => {
      const { isBusiness, isCategory } = payload.meta;
      const isParamsChanged =
        state.isBusiness !== isBusiness || state.isCategory !== isCategory;

      if (isParamsChanged) {
        return {
          ...state,
          productListShort: payload.results,
          hasMore: !!payload.next,
          notFound: !payload.results.length,
          nextKey: payload.key,
          isBusiness,
          isCategory,
        };
      }

      return {
        ...state,
        productListShort: !payload.previous
          ? payload.results
          : [...state.productListShort, ...payload.results],
        hasMore: !!payload.next,
        notFound: !payload.results.length,
        nextKey: payload.key,
        isBusiness,
        isCategory,
      };
    },
    [actions.getProductListShortFail]: (state, { payload }) => ({
      ...state,
      error: payload,
      notFound: true,
    }),
    [actions.getProductListFail]: (state, { payload }) => ({
      ...state,
      error: payload,
      notFound: true,
    }),
    [actions.setPrevParams]: (state, { payload }) => ({
      ...state,
      prevParams: payload,
    }),
    [actions.getProductInfoSuccess]: (state, { payload }) => ({
      ...state,
      productInfo: payload,
    }),
    [actions.clearProduct]: (state) => ({
      ...state,
      productInfo: null,
    }),
    [actions.clearProductListShort]: (state) => ({
      ...state,
      productListShort: [],
    }),
    [actions.getProductInfoFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
    [actions.getClaimCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      claimCategories: payload,
    }),
    [actions.getClaimCategoriesFail]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
  initialState,
);

export const reducer = combineReducers({
  data,
  isListLoading,
  isProductLoading,
  isClaimCategoriesLoading,
});

export const key = 'products';

export const productSelectors = {
  productListShort: (state) => getStateValue(state, key, 'productListShort'),
  hasMore: (state) => getStateValue(state, key, 'hasMore'),
  notFound: (state) => getStateValue(state, key, 'notFound'),
  nextKey: (state) => getStateValue(state, key, 'nextKey'),
  isListLoading: (state) => getStateValue(state, key, 'isListLoading', true),
  productInfo: (state) => getStateValue(state, key, 'productInfo'),
  claimCategories: (state) => getStateValue(state, key, 'claimCategories'),
  isProductLoading: (state) => getStateValue(state, key, 'isProductLoading'),
  prevParams: (state) => getStateValue(state, key, 'prevParams'),
  isBusiness: (state) => getStateValue(state, key, 'isBusiness'),
  isCategory: (state) => getStateValue(state, key, 'isCategory'),
};
