import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import IconCategoriesComponent from 'components/IconCategories';
import SelectedCategory from 'components/IconCategories/SelectedCategory/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from 'react-router';
import messages from 'messages/Filter';
import { appSelectors } from 'providers/AppProvider/data';
import { useDispatch, useSelector } from 'react-redux';
import { useMainCategories } from 'hooks/useMainCategories';
import { useCategories } from 'hooks/useCategories';
import { productActions } from 'states/Products';

function getObjectLineFromTree(tree, callback) {
  if (!tree) return [];
  return tree.reduce((objectLine, obj) => {
    if (callback(obj)) {
      return [obj];
    }
    if ('children' in obj === false) return objectLine;
    const targetObjectLine = getObjectLineFromTree(obj.children, callback);
    const targetObject = targetObjectLine[targetObjectLine.length - 1];
    if (targetObject && callback(targetObject)) {
      return [obj, ...targetObjectLine];
    }
    return objectLine;
  }, []);
}

function IconCategories({ intl }) {
  const { category: categoryName, subcategory, tripcategory } = useParams();
  const dispatch = useDispatch();
  const categories = useSelector(appSelectors.categories);
  const mainCategories = useSelector(appSelectors.mainCategories);

  const { getMainCategories } = useMainCategories();

  const list = useMemo(() => {
    const localCategories = JSON.parse(localStorage.getItem('iconCategories'));
    if (categories && categories.length > 0) return categories;
    if (localCategories) return localCategories;
    return null;
  }, [categories]);

  const [categoryLine, setCategoryLine] = useState([]);

  const title = tripcategory
    ? tripcategory
    : subcategory
    ? subcategory
    : categoryName;

  const getCategoryLine = useCallback(
    async (titleSlug = title, tree = list) => {
      if (!titleSlug) return [];

      const line = getObjectLineFromTree(
        tree,
        (cat) => cat.title_slug === titleSlug,
      );
      if (line.length > 0) setCategoryLine(() => line);
    },
    [categoryName, list, subcategory, tripcategory],
  );

  useEffect(() => {
    getCategoryLine();

    if (mainCategories && mainCategories.length) return;
    getMainCategories();

    return () => null;
  }, [getCategoryLine]);

  const { getCategories } = useCategories();

  useEffect(() => {
    if (categories.length) return;
    getCategories();
  }, []);

  const handleClick = useCallback(() => {
    dispatch(productActions.clearProductListAsync());
  }, []);

  const currentCategory = useMemo(() => {
    return categoryLine.length > 0
      ? categoryLine[categoryLine.length - 1]
      : null;
  }, [categoryLine]);

  if (!list || !mainCategories) {
    return (
      <div
        style={{
          width: '100%',
          height: 120,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return currentCategory ? (
    <SelectedCategory
      category={currentCategory}
      categoryLine={categoryLine}
      onClick={handleClick}
      intl={intl}
    />
  ) : (
    <IconCategoriesComponent
      categories={list}
      onClick={handleClick}
      messages={messages}
      intl={intl}
    />
  );
}

IconCategories.propTypes = {
  intl: intlShape,
};

const withMemo = memo(IconCategories);

export default injectIntl(withMemo);
